import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Util from './../data/General';
import * as Constants from './../store/index';
import Button from '../components/html/Button';

import mixpanel from 'mixpanel-browser';

//const COST = {"MX":"0.032","CL":"0.04","CO":"0.015","PE":"0.05","ES":"0.046","US":"0.018","CA":"0.018","BR":"0.042","DE":"0.102","IL":"0.023","NG":"0.038","TR":"0.015","SA":"0.03","FR":"0.092","IT":"0.05","GB":"0.048","AR":"0.049","EG":"0.082","IN":"0.005","MY":"0.024","PK":"0.03","RU":"0.057","AE":"0.024","ID":"0.024","NL":"0.096","ZA":"0.024","BO":"0.059","CR":"0.059","DO":"0.059","EC":"0.059","SV":"0.059","GT":"0.059","HT":"0.059","HN":"0.059","JM":"0.059","NI":"0.059","PA":"0.059","PY":"0.059","PR":"0.059","UY":"0.059","VE":"0.059","AF":"0.057","AU":"0.057","BD":"0.057","KH":"0.057","CN":"0.057","HK":"0.057","JP":"0.057","LA":"0.057","MN":"0.057","NP":"0.057","NZ":"0.057","PG":"0.057","PH":"0.057","SG":"0.057","LK":"0.057","TW":"0.057","TJ":"0.057","TH":"0.057","TM":"0.057","UZ":"0.057","VN":"0.057","BH":"0.024","IQ":"0.024","JO":"0.024","KW":"0.024","LB":"0.024","OM":"0.024","QA":"0.024","YE":"0.024","AL":"0.074","AM":"0.074","AZ":"0.074","BY":"0.074","BG":"0.074","HR":"0.074","CZ":"0.074","GE":"0.074","GR":"0.074","HU":"0.074","LV":"0.074","LT":"0.074","MK":"0.074","MD":"0.074","PL":"0.074","RO":"0.074","RS":"0.074","SK":"0.074","SI":"0.074","UA":"0.074","DZ":"0.02","AO":"0.02","BJ":"0.02","BW":"0.02","BF":"0.02","BI":"0.02","CM":"0.02","TD":"0.02","CG":"0.02","ER":"0.02","ET":"0.02","GA":"0.02","GM":"0.02","GH":"0.02","GW":"0.02","CI":"0.02","KE":"0.02","LS":"0.02","LR":"0.02","LY":"0.02","MG":"0.02","MW":"0.02","ML":"0.02","MR":"0.02","MA":"0.02","MZ":"0.02","NA":"0.02","NE":"0.02","RW":"0.02","SN":"0.02","SL":"0.02","SO":"0.02","SS":"0.02","SD":"0.02","SZ":"0.02","TZ":"0.02","TG":"0.02","TN":"0.02","UG":"0.02","ZM":"0.02","AT":"0.05","BE":"0.05","DK":"0.05","FI":"0.05","IE":"0.05","NO":"0.05","PT":"0.05","SE":"0.05","CH":"0.05"};
const LIMITED = [30, 40, 50, 60, 100, 150, 200, 300,400, 500, 700, 1000, 1200, 1500, 2000, 2500, 3000, 5000, 10000];
class Plan extends Component {

    state = { 
        costMenssage: null,
        costMenssageDolar: null,
        costMenssageMK: null,
        costMenssageMKDolar: null,
        plans: {
            "FREE":{
                id: "1",
                text: "option_free_10", 
                name: "FREE",
                amount: 0, 
                cost: "$0.08",
                selected: true,
                limitedMin: 30,
                limited: 30,
                extra:[ "option_free_1", "option_free_2"]
            },"LITE":{
                id: "1",
                text: "option_free_11", 
                previus: "FREE",
                name: "LITE",
                amount: 9, 
                cost: "COUNTRY",
                selected: true,
                limitedMin: 60,
                limited: 60,
                extra:[ "option_200_2","option_200_new","option_1000_4"]
            },"PRO":{
                id: "1",
                previus: "LITE",
                text: "option_free_12", 
                name: "PRO",
                amount: 39, 
                selected: true,
                cost: "COUNTRY",
                limitedMin: 100,
                limited: 100,
                extra:[ "option_500_2","option_200_ia", "option_500_new","option_500_new_1", "option_500_new_2","option_1000_new","option_1000_new_1"]
            },"PLUS":{
                id: "1",
                previus: "PRO",
                text: "option_free_13", 
                name: "PLUS",
                amount: 99, 
                selected: true,
                cost: "COUNTRY",
                limitedMin: 400,
                limited: 400,
                extra:["option_2000_new","option_2000_new_1","option_PLUS_01","option_PLUS_02" ]
            },"0":{
                id: "0",
                text: "option_free_0", 
                name: "0",
                amount: 0, 
                review: 0,
                selected: true,
                isOnlyPay: true,
                extra:[  "option_free_1", "option_free_2"]
            },"50":{
                id: "1",
                text: "option_free_new", 
                name: "50",
                amount: 0, 
                review: 50,
                selected: true,
                limited: "4.00",
                cost: "$0.08",
                menssEsp: ["option_free_new_1"],
                isOnlyPay: true,
                extra:["option_free_1", "option_free_2"]
            },
            "200":{
                id: "1",
                text: "option_200", 
                name: "200",
                amount: 9, 
                offer: 19,
                textOffer: "offer1",
                review: 200,
                selected: false,
                previus: 50,
                isOnlyPay: true,
                extra:[ "option_200_2","option_200_new","option_1000_4","option_200_ia","option_free_3"]
            },
            "500":{
                id: "1",
                text: "option_500", 
                name: "500",
                amount: 29, 
                review: 500,
                selected: false,
                previus: 200,
                isOnlyPay: true,
                extra:[  "option_500_2","option_500_new","option_500_new_1", "option_500_new_2"]
            },
            "1000":{
                id: "1",
                text: "option_1000", 
                name: "1000",
                amount: 59, 
                review: 1000,
                selected: false,
                previus: 500,
                isOnlyPay: true,
                extra:[  "option_1000_new","option_1000_new_1"]
            },
            "2000":{
                id: "1",
                text: "option_2000", 
                name: "2000",
                amount: 99, 
                review: 2000,
                selected: false,
                previus: 1000,
                isOnlyPay: true,
                extra:[ "option_2000_new","option_2000_new_1"]
            },
            "6000":{
                id: "1",
                text: "option_6000", 
                name: "6000",
                amount: "----", 
                review: 6000,
                selected: false,
                previus: 2000,
                isOnlyPay: true,
                extra:[ "option_2000_new","option_2000_new_1"]
            }
        },
        plansTN: {
            "0":{
                id: "0",
                text: "option_free_TN", 
                name: "0",
                amount: 0, 
                review: 0,
                selected: true,
                extra:[ "option_free_1", "option_free_2"]
            },
            "50":{
                id: "1",
                text: "option_free", 
                name: "50",
                amount: 5, 
                review: 50,
                selected: true,
                previus: "0",
                extra:[,"option_free_3"]
            },
            "200":{
                id: "1",
                text: "option_200", 
                name: "200",
                amount: 9, 
                offer: 19,
                textOffer: "offer1",
                review: 200,
                selected: false,
                previus: 50,
                extra:[ "option_200_2","option_200_new","option_1000_4","option_200_ia"]
            },
            "500":{
                id: "1",
                text: "option_500", 
                name: "500",
                amount: 29, 
                review: 500,
                selected: false,
                previus: 200,
                extra:[  "option_500_2","option_500_new","option_500_new_1", "option_500_new_2"]
            },
            "1000":{
                id: "1",
                text: "option_1000", 
                name: "1000",
                amount: 59, 
                review: 1000,
                selected: false,
                previus: 500,
                extra:[  "option_1000_new","option_1000_new_1"]
            },
            "2000":{
                id: "1",
                text: "option_2000", 
                name: "2000",
                amount: 99, 
                review: 2000,
                selected: false,
                previus: 1000,
                extra:[ "option_2000_new","option_2000_new_1"]
            }
        },
        plansTNNEW: {
            "FREE":{
                id: "1",
                text: "option_free_10", 
                name: "FREE",
                amount: 0, 
                cost: "$0.08",
                selected: true,
                limitedMin: 30,
                limited: 30,
                extra:[ "option_free_1", "option_free_2"]
            },"LITE":{
                id: "1",
                text: "option_free_11", 
                previus: "FREE",
                name: "LITE",
                amount: 9, 
                cost: "COUNTRY",
                selected: true,
                limitedMin: 60,
                limited: 60,
                extra:[ "option_200_2","option_200_new","option_1000_4"]
            },"PRO":{
                id: "1",
                previus: "LITE",
                text: "option_free_12", 
                name: "PRO",
                amount: 39, 
                selected: true,
                cost: "COUNTRY",
                limitedMin: 100,
                limited: 100,
                extra:[ "option_500_2","option_200_ia", "option_500_new","option_500_new_1", "option_500_new_2","option_1000_new","option_1000_new_1"]
            },"PLUS":{
                id: "1",
                previus: "PRO",
                text: "option_free_13", 
                name: "PLUS",
                amount: 99, 
                selected: true,
                cost: "COUNTRY",
                limitedMin: 400,
                limited: 400,
                extra:["option_2000_new","option_2000_new_1","option_PLUS_01","option_PLUS_02" ]
            },"0":{
                id: "0",
                text: "option_free_0", 
                name: "0",
                amount: 0, 
                review: 0,
                selected: true,
                isOnlyPay: true,
                extra:[  "option_free_1", "option_free_2"]
            },"50":{
                id: "1",
                text: "option_free_new", 
                name: "50",
                amount: 0, 
                review: 50,
                selected: true,
                limited: "4.00",
                cost: "$0.08",
                menssEsp: ["option_free_new_1"],
                isOnlyPay: true,
                extra:["option_free_1", "option_free_2"]
            },
            "200":{
                id: "1",
                text: "option_200", 
                name: "200",
                amount: 9, 
                offer: 19,
                textOffer: "offer1",
                review: 200,
                selected: false,
                previus: 50,
                isOnlyPay: true,
                extra:[ "option_200_2","option_200_new","option_1000_4","option_200_ia","option_free_3"]
            },
            "500":{
                id: "1",
                text: "option_500", 
                name: "500",
                amount: 29, 
                review: 500,
                selected: false,
                previus: 200,
                isOnlyPay: true,
                extra:[  "option_500_2","option_500_new","option_500_new_1", "option_500_new_2"]
            },
            "1000":{
                id: "1",
                text: "option_1000", 
                name: "1000",
                amount: 59, 
                review: 1000,
                selected: false,
                previus: 500,
                isOnlyPay: true,
                extra:[  "option_1000_new","option_1000_new_1"]
            },
            "2000":{
                id: "1",
                text: "option_2000", 
                name: "2000",
                amount: 99, 
                review: 2000,
                selected: false,
                previus: 1000,
                isOnlyPay: true,
                extra:[ "option_2000_new","option_2000_new_1"]
            },
            "6000":{
                id: "1",
                text: "option_6000", 
                name: "6000",
                amount: "----", 
                review: 6000,
                selected: false,
                previus: 2000,
                isOnlyPay: true,
                extra:[ "option_2000_new","option_2000_new_1"]
            }
        },
        shop: null
    };

    componentDidMount() {
        Util.getInfoShop(this.handleOkShopPlan, this.handleOkShopError);
        let shopJson =  Util.getAdminShop();
        if(shopJson){
            this.setState({shop: shopJson});
            mixpanel.init(window.mixpanelKey); 
            mixpanel.track('AD Plan', {
                'shopDomain': shopJson.domain,
                'shopId': shopJson.id,
            });
            
            if(Util.isTiendaNube(shopJson)){

                const { plansTN, plansTNNEW } = this.state;
                if("tn-2686675" !== shopJson.id){
                    this.setState({plans: plansTN});
                }else{
                    this.setState({plans: plansTNNEW});
                }
            }else if(shopJson && shopJson.plan && (shopJson.plan.planType === "FREE" || shopJson.plan.planType === "LIVE" || shopJson.plan.planType === "PRO" || shopJson.plan.planType === "PLUS")){
                const { plans } =this.state;
                plans[shopJson.plan.planType].limited = shopJson.plan.limitCost;
                this.setState({plans: plans});
            }
            
        }
        Util.getCurrency(this.isOKRates);
    }
    
    isOKRates = (data) => {
        let shopify =  Util.getAdminShop();
        if (shopify && shopify.countryCode && data[shopify.countryCode]){
            let dat = data[shopify.countryCode];
            if(dat.rate){
                this.setState({costMenssageMK: (dat.mkt * dat.rate), costMenssageMKDolar: dat.mkt, costMenssage: (dat.revie* dat.rate), costMenssageDolar: dat.revie});
            }
        }
    }

    handleOkShopPlan = (data) => {
        console.log(data);
        if (data && data.id){
            this.setState({shop: data});
            localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(data));
        }
      }
    
      handleOkShopError = (data) => {
        console.log(data);
      }


    onChange = (value, name) => {
        const { shop } = this.state;
        console.log(value);
        console.log(shop);
        shop[name] = (value !== "" ? value : null); 
        console.log(shop);
        this.setState({shop: shop});
        Util.setInfoShop(this.handleOkShop, this.handleOkShoperror, shop);
    }

    onBlur = (value, name) => {
        const { shop } = this.state;
        shop[name] = value; 
        console.log(shop);
        this.setState({shop: shop});
    }

    onUpDate = (name) => {
        const { shop } = this.state;
        Util.setInfoShop(this.handleOkShop, this.handleOkShoperror, shop);
    }

    handleOkShop = (data) => {
        if (data && data.id){
            localStorage.setItem(Constants.storage.USER_SHOP, JSON.stringify(data));
        }
    }

    handleOkShoperror = (data) => {
        console.log(data);
    }

    setPlan = (plan) => {
        const { plans, shop, costMenssage } = this.state;
        
        let newPlan = plans[plan];
        mixpanel.init(window.mixpanelKey); 
        mixpanel.track('Change Plan '+ newPlan.name, {
            'shopDomain': shop.domain,
            'shopId': shop.id,
        });
        let cost = costMenssage ? costMenssage : "0.06" ;

        if(Util.isShopify(shop)){
            let accessTokenStore =  localStorage.getItem(Constants.storage.USER_ACCESS);
            console.log(window.url_server_2+"suscription/?shop="+Util.getMetafieldMyshopify_domain()+"&plan_name="+newPlan.name+"&price="+newPlan.amount+"&access="+accessTokenStore);
            window.location = window.url_server_2+"suscription/?shop="+Util.getMetafieldMyshopify_domain()+"&plan_name="+newPlan.name+"&price="+newPlan.amount+"&access="+accessTokenStore+"&capped_amount="+newPlan.limited+"&cost="+cost;
        }else{
            if("tn-2686675" !== shop.id){
                let shopId= shop.id.replace("tn-", "");
                let accessTokenStore =  localStorage.getItem(Constants.storage.USER_ACCESS);
                window.location = "https://tiendanube.triciclogo.com/strike/public/?shop="+shopId+"&plan="+newPlan.name+"&access="+accessTokenStore+"&shop_Name="+shop.name;
                //https://tiendanube.triciclogo.com/strike/public/?plan=200&shop=2686675&access=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InRqdTlhM1VSVkVxQTV0Tk14bkNGcCJ9.eyJpc3MiOiJodHRwczovL3JldmllLnVzLmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw2M2U1NWEwYTM2YjQ4NjU0MjZhYTY3NmEiLCJhdWQiOlsiaHR0cHM6Ly9hcGkucmV2aWUubGF0IiwiaHR0cHM6Ly9yZXZpZS51cy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNjc4MTMxMzE3LCJleHAiOjE2NzgyMTc3MTcsImF6cCI6InQ3NkdxNnQybnVpbVJVMkJYVlRGcHFXSlg3U2JkOWJWIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImd0eSI6InBhc3N3b3JkIiwicGVybWlzc2lvbnMiOltdfQ.XCcIfEp-yrNmSfZXK6Hm5egFb0gUOmoTdUc5Lc_9DntjUhedLFWeyIMTmeaDrNQ5rYbQGexu7mHTDcouNpYxeLPsxVlNZtdT5lFtmSBJlxd_qztR9Vu4fQEbryUOzAm0Jh86W74t9Jt6yHQLTb98lSee1_MmWAc2pn76jAXQ9y2LLsbo_oEj1e0jRGQWH9NtYo28ihV3s-93EqHzeMv-vR8LuS-_mQbO064lmo_PwKkCkIlmsMMSyDXyf6YSiAfTP8q39FAKhwVH7yh5HieLUv7kdPD4GXtNeog_GzY5iUMEIjim5Hpa3ufYOdu9uTHH6aabBs_4E5B-XC0UYh4JMw
            }else{
                let shopId= shop.id.replace("tn-", "");
                let accessTokenStore =  localStorage.getItem(Constants.storage.USER_ACCESS);
                window.location = "https://tiendanube.triciclogo.com/stripe/public/?shop="+shopId+"&plan="+newPlan.name+"&access="+accessTokenStore+"&shop_Name="+shop.name;
                 
            }
        }
    }
    /*
    isFree = (date) => {
        try {
            let dateFinish = new Date("2022-12-01").getTime();
            let myArray = date.split("T");
            let dateCreate = new Date(myArray[0]).getTime();
            console.log(dateFinish);
            console.log(dateCreate);
            console.log(dateFinish <= dateCreate);
            if (dateFinish > dateCreate) {
                return true;
            }
        } catch (error) {
          console.error(error);
        }

        return false;
    }*/

    onChangeLimited = (value, plan) => {
        const { plans } = this.state;
        plans[plan].limited = value;
        this.setState({plans: plans});
        this.setPlan(plans[plan].name)
    }

    render() {
        const { plans, shop, costMenssageMKDolar, costMenssageDolar } = this.state;
        return (
            <div className="content-config">
                <div className='title'>{Util.getMessage("option_plan")}</div>
                <div className='content-flex content-plan'>
                    {plans && Object.keys(plans).map((plan, key) => (
                        <div key={key} className={shop && shop.plan.planType === plans[plan].name ? 'active' : shop && plans[plan].isOnlyPay ? 'none' : ''}>
                            <div className='actual'>{Util.getMessage("plan_actual")}</div>
                            
                            <div className='name' dangerouslySetInnerHTML={{__html: Util.getMessage(plans[plan].text)}} />
                            {!plans[plan].offer ? (
                                <div className='cost'> USD {plans[plan].amount}
                                {/*shop && plans[plan].name === "50" && this.isFree(shop.creationDate) ? (
                                    <>$ 0</>
                                ) : (
                                    <>$ {plans[plan].amount}</>
                                )*/}
                                <small> / {Util.getMessage("month")}</small></div>

                            ) : (
                                <>
                                    <div className='cost offer'><del> $ {plans[plan].offer}</del> <small>{Util.getMessage("textOffer")}</small></div>
                                    <div className='cost'>USD {plans[plan].amount} <small>/ {Util.getMessage("month")}</small></div>
                                </>
                            )}
                            
                            {/*shop && shop.plan.planType === plans[plan].name && Util.isShopify(shop) && Util.isChargePerMessage() && (
                                <div className='charge-amount'>${shop.plan.currentReview *0.08} {Util.getMessage("plan_spent")} | {Util.getMessage("plan_limit")} ${plans[plan].limited}</div>
                            )*/}
                            {/*<div className='content-charge-amount'>
                                    <progress className="Polaris-ProgressBar" value={shop.plan.currentReview *0.08} max={plans[plan].limited}></progress>
                                    <div className='charge-amount'>${shop.plan.currentReview *0.08} USD gastados | Límite ${plans[plan].limited} USD</div>
                                </div> */}
                            <Button value={Util.getMessage("plan_select")} type="primary" onClick={() => this.setPlan(plans[plan].name)}/>
                            {shop && plans[plan].limitedMin && Util.isShopify(Util.getAdminShop()) && (
                                <div className='limited'>
                                    {Util.getMessage("option_limited")}
                                    <select value={shop.plan.limitCost} onChange={e => this.onChangeLimited(e.target.value, plan)}>
                                        {LIMITED.map((lim, key) => (<>{lim >= plans[plan].limitedMin ? <option key={key} value={lim}>$ {lim}</option> :<></>}</>))}
                                    </select>
                                </div>
                            )}
                            {shop && shop.plan.planType === plans[plan].name && (shop.plan.planType !== "0" && shop.plan.planType !== "FREE" && shop.plan.planType !== "PRO"  && shop.plan.planType !== "LITE"  && shop.plan.planType !== "PLUS") && (
                                <div className='plan-actual'>
                                    <div id="progressbar">
                                        <div style={{width: ((shop.plan.currentReview * 100) / shop.plan.totalReview).toFixed(1)+ "%"}}>{((shop.plan.currentReview * 100) / shop.plan.totalReview).toFixed(1)+ "%"}&nbsp;&nbsp;</div>
                                    </div>
                                    <div>
                                        <small><b>{shop.plan.currentReview}</b> {Util.getMessage("plan_reviews_to")} <b>{shop.plan.totalReview}</b>{Util.isShopify(shop) && shop.plan.planType === plans[plan].name && plans[plan].name === "0" && " FREE"}</small>
                                    </div>
                                </div>
                                
                            )}
                            {shop && shop.plan.currentReview === shop.plan.totalReview && (
                                <div className='actual proximo'>{Util.getMessage("plan_finish")} </div>
                            )}
                            <ul className='list-plan'>
                                {shop && plans[plan].cost && (
                                    <li>
                                        {plans[plan].cost=== "COUNTRY" ? 
                                            Util.getMessage("option_free_cost_inc") + " $" + (costMenssageDolar ? (costMenssageDolar.toFixed(3)) : "0.019 / 0.074" )
                                        : Util.getMessage("option_free_cost_inc") + " " +plans[plan].cost}
                                    </li>
                                )} 
                                {/*<li>
                                    {plans[plan].review + " "}{Util.getMessage("option_plan_number")}
                                </li>*/}
                                {plans[plan].previus && (
                                    <li>
                                        {Util.getMessage("option_plan_number_sub")} {" " +plans[plan].previus }
                                    </li>
                                )}
                                {plans[plan].menssEsp && plans[plan].menssEsp.map((extra, keyExtra) => (
                                    <li key={keyExtra}>
                                        {Util.getMessage(extra)}
                                    </li>
                                ))}
                                {plans[plan].extra && plans[plan].extra.map((extra, keyExtra) => (
                                    <li key={keyExtra}>
                                        {extra === "option_PLUS_02" ? (
                                            <>{Util.getMessage(extra)}: ${costMenssageMKDolar ? costMenssageMKDolar : "0.00"}</>
                                        ) : (
                                            <>{Util.getMessage(extra)}</>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            
                            
                        </div>
                    ))}
                </div>
                
            </div>
            );
        }
    }

    
const mapStateToProps = (state) => ({
    packageList: state.packageList,
    shopState: state.shop,
    user: state.user,
    ordens: state.ordens,
    shippings: state.shippings,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Plan);