import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import  * as Util from './../data/General';

class Menu extends Component {
  //Requerimientos del menu
  static propTypes = {
    items: PropTypes.array.isRequired,
    
  }

  state = {
    shopify : null
  }

  //Constructor
  constructor (props){
    super(props);
    
  }
  //Se cargo en pantalla el componente
  componentDidMount(){
    var shopJson =  Util.getAdminShop();
    if (shopJson){
      this.setState({shopify: shopJson});
    }else{
      setTimeout(function(){
        var shopJson =  Util.getAdminShop();
        if (shopJson){
          this.setState({shopify: shopJson});
        }
      }.bind(this), 500);
    }
 
  }

  
  
  handleClickMenu = (new_option) => {
    const { menuShow, isMobile } = this.props;
    this.props.dispatch({
      type: 'CHANGE_MENU',
      new_option: new_option
    });

    if (isMobile && menuShow){
      this.props.dispatch({
        type: 'CHANGE_SHOW_MENU'
      });
    }
    return false;
  } 

  handleClickRecharge = () => {

    /*var left = (window.screen.width/2 - 250) ;
    var top = (window.screen.height/2 - 150) ;
    let popup = window.open("/recharge-popup", "Recarga", "width=500,height=300,top="
    + top + ', left=' + left);


    window.popupOpen = popup;*/


    /*
    ,{
    "url": "/cuestion",
    logo: 'img/menu/cuestion.png',
    logo_active: 'img/menu/cuestion_active.png',
    title: "Preguntas"
},{
    "url": "/preference",
    logo: 'img/menu/preference.png',
    logo_active: 'img/menu/preference_active.png',
    title: "Preferencia"
}
    */
}

changeAmount = (value) =>{
  this.setState({ url: value});
};

getMenu = (item, menuSelected, shop, addClass="") => {
  let { shopify }  =  this.state;
  
  return (((shopify && 
    (!item.isShow || Util.isShow(shop, item.isShow)) && 
    (!item.shopType || shopify.shopType === item.shopType)) &&
    (!item.ShopifyOrIDs || (shopify.shopType === "SHOPIFY" || item.ShopifyOrIDs.includes(shopify.id))) && 
    ((!item.planType || shopify.plan.planType === item.planType) &&
    (!item.variableTrue || shopify[item.variableTrue] === true) &&
    (!item.id || shopify.id === item.id) &&
    (!item.ids || item.ids.includes(shopify.id)) 
    ) 
  ) ? (
    <li onClick={()=> this.handleClickMenu(item.url)} className={`${addClass} ${(menuSelected === item.url) ? "active" : ""}`}>
    <div>
      {item.logo ? (
        <img src={(menuSelected === item.url) ? item.logo_active : item.logo} className="menu-logo"/>
      ) : (<div className='submenu'></div>)}

      {item.title && (
        <div>{Util.getMessage(item.title)}</div>
      )}
    </div>
    {/*<hr className={(menuSelected === item.url) ? "active" : ""}></hr>*/}
  </li>
   ) : null
  );
}
  
 
  render() {
    const { menuSelected, shop } = this.props;
    const itemsMenu = this.props.items;
    return (
      <div id="menu" className="menu">
        <ul>
          {itemsMenu && itemsMenu.map((item, key) => 
            <React.Fragment key={key}>
              {this.getMenu(item, menuSelected, shop)}
              {(item.submenu && menuSelected.startsWith(item.url) ? (
                <>
                {item.submenu.map((itemN, keyN) => 
                <>
                  <React.Fragment key={keyN}>{this.getMenu(itemN, menuSelected, shop, "sub")}</React.Fragment>
                  {(itemN.submenu && menuSelected.startsWith(itemN.url) ? (
                    itemN.submenu.map((itemM, keyM) => <React.Fragment key={keyM}>{this.getMenu(itemM, menuSelected, shop, "sub submn")}</React.Fragment>)
                  ) : null)}
                  
                </>
                )}
                </>
              ) : null)}
            </React.Fragment>
            )}
        </ul>
        <div className='contact'>
          <div className='no-tablet'>{Util.getMessage("contact")}</div>
          <div>
            <div>
              <a href='mailto:hola@revie.lat' target='_blank'><img src='/img/correo-electronico-vacio.png' /></a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  menuSelected: state.menu.menuSelected,
  menuShow: state.menu.menuShow,
  isMobile: state.system.isMobile,
  shop: state.shop
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
