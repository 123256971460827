import React, { useState, useEffect } from 'react';

import * as Util from '../data/General';
import InputHtml from '../components/html/InputHtml';
import SelectHtml from '../components/html/SelectHtml';
import Button from '../components/html/Button';

function CreateSegmentsIA(props) {
    const { cancel, setNewSegment, title="flow_create", sub_title="flow_create_sub", type="flow" } = props;
 const [text, setText] = useState("");
  const [config, setConfig] = useState(null);
  const [valide, setValide] = useState(true);  
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [counter, setCounter] = useState(null);

  const [textQuery, setTextQuery] = useState("");
  const [errorCount, setErrorCount] = useState(null);
  
  useEffect(() => {
    if(!config){
        //setConfig(configFlow.config);
        //setData(configFlow.config);
    }
  },[text]);

  const onChange = (value, name) => {
    config[name].value = value;
    setConfig(config);
    setData(config);
  }

  const setData = (config) => {
    //let text = convert(Util.getMessage(configFlow.text_config), config);
    //setTextQuery(text);

    //let query = convert(configFlow.query, config);
    //setQuery(query);

    let valide = true;
    Object.keys(config).map((key, index) => {
        if(config[key].value === "" || config[key].value === null){
            valide = false;
        }
    });
    setValide(valide);
  }

  const convert = (text, config) => {
    {config && Object.keys(config).map((key, index) => {
        text = text.replace(`[${key}]`, config[key].value);
        {config[key].aditional && Object.keys(config[key].aditional).map((keyA, indexA) => {
            text = text.replace(`[${keyA}]`, config[key].value + config[key].aditional[keyA]);
        })}
        {config[key].subtract && Object.keys(config[key].subtract).map((keyA, indexA) => {
            text = text.replace(`[${keyA}]`, config[key].value - config[key].subtract[keyA]);
        })}
    })}

    return text;
  }
  const newFlow = () => {
      let data = {
        name: textQuery+"-[REVIE-IA]",
        query: query
      }
      console.log(data);
      Util.newFlow(data, isOk, isError);
  }

  const isOk = (data) => {
    console.log("data", data);
    if(data && data.id && setNewSegment){
        setNewSegment(data);
    }
  }

  const isError = (data) => {
    console.log("data", data);
  }

  const generate = () => {
    setErrorCount(null);
    setLoader(true);
    setCounter(null);
    Util.getSegmentsPHP(textQuery, isOKGenerate, isErrorGenerate);
  }

  const isOKGenerate = (data) => {
    console.log("data", data);
    setLoader(false);
    if(data && data.data){
        setQuery(data.data);
        Util.membersCount(null, data.data, isOKGenerateCount, isErrorGenerate);
    }
  }
  const isOKGenerateCount = (data) => {
    console.log("data", data);
    setCounter(data.totalCount)
  }

  const isErrorGenerate = (data) => {
    console.log("data", data);
    setErrorCount(Util.getMessage("segment_count_error"));
  }
  
  return (
    <div className="create-flow content-reviews content-segments-ia">
        <div className="title content-flex space-between space-top">
            {Util.getMessage("hearings_ia_page_title")}
        </div>

        <p>{Util.getMessage("hearings_ia_page_description_1")}</p> 
        <p>{Util.getMessage("hearings_ia_page_description_2")}</p>
        <textarea onChange={(e) => setTextQuery(e.target.value)} value={textQuery}></textarea>
        <div className='button_campaign'>
            <Button value={Util.getMessage("generate")} disabled={textQuery === "" || loader} type="primary" onClick={() => generate()}/>
            <Button value={Util.getMessage("Volver")} onClick={() => {cancel()}}/>
        </div>
        {query !== "" && !loader ? (
            <div className='content-segment-ia-ok'>
                <pre>{query}</pre>
                {errorCount ? <div className='error'>{errorCount}</div> : <Button value={Util.getMessage("create")} type="primary" onClick={() => newFlow()}/>}
                {counter ? <div>{Util.getMessage("segment_count").replace("{count}",counter)}</div> : null}
                <small>{Util.getMessage("hearings_ia_page_help")}</small>
            </div>
        ) : null}
        
        <h3>{Util.getMessage("hearings_ia_page_example")}</h3>
        <div className='content-exampleia'>
            {[...Array(10)].map((x, i) =>
                <div key={i} onClick={() => {setTextQuery(Util.getMessage("h_ia_example_"+i)); setQuery("")}}>{Util.getMessage("h_ia_example_"+i)}</div>
            )} 
        </div>
    </div>
);
}

export default CreateSegmentsIA;