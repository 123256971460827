import { Component } from 'react';
import { connect } from 'react-redux';
import * as Constants from './../store/index';
import axios from "axios";

export const STATEREVIEWS = {
  "APPROVED": "APPROVED",
  "PENDING_APPROVAL": "PENDING_APPROVAL",
  "REJECTED" :"REJECTED",
  "NEW" :"NEW",
  "SENT" :"SENT",
}

export const LANG = {
  "es": "es",
  "en": "en",
  "pt" :"pt",
}

export const DAYS = {
  "-1": "never",
  "0": "now",
  "1": "1day",
  "2": "2day",
  "3": "3day",
  "5": "5day",
  "7": "7day",
  "10": "10day",
  "15": "15day",
  "30": "30day",
}

export const LIMITWHATSAPP= {
  "Unavailable":1000,
  "1 Customers/24hr": 1,
  "500 Customers/24hr": 500,
  "1K Customers/24hr": 1000,
  "10K Customers/24hr": 10000,
  "100K Customers/24hr": 100000,
  "1000K Customers/24hr": 1000000,
}

export const DAYS_INSTALL = {
  "-1": "never_install",
  "0": "now",
  "1": "1day",
  "2": "2day",
  "3": "3day",
  "5": "5day",
  "7": "7day",
  "10": "10day",
  "15": "15day",
  "30": "30day",
}

export const DAYS_REQUEST = {
  "-1": "never_request",
  "7": "7day",
  "15": "15day",
  "30": "30day",
  "60": "60day",
}

export const STARS = {
  "": "AllStar",
  "1": "1Star",
  "2": "2Star",
  "3": "3Star",
  "4": "4Star",
  "5": "5Star",
}

export const LISTSUBTYPE=["ALL", "WC.title", "CA.title", "R.title", "BA.title","PFU.title","WS.title"];
export const EXAMPLE_TAB=3;
export const SHOP_ADMIN_ID="60770517158";

let shopAdmin = null;
let shopAdminmyshopify = "";
let InfoAditionalUrl = "";
let shopURLConfig = "";
let ShopChargePerMessage= null;


export var getDateFormat = (dateText) => {
  var today  = new Date(dateText);
  //console.log(today);
  //return new Intl.DateTimeFormat().format(today);
  return today.toString();
}
export var setError = () => {
  let ecommerceName = getEcommerce();
  InfoAditionalUrl = getInfoUrlError(ecommerceName);
  let ECOMMERCE = {
    "NULL": "https://revie.lat/",
    "SHOPIFY": `https://${InfoAditionalUrl}/admin`,
    "TIENDANUBE": `https://tiendanube.triciclogo.com/view/?locale=${InfoAditionalUrl}`,
    "VTEX": `https://vtex.triciclogo.com/view/?locale=${InfoAditionalUrl}`,
    "3": "3Star",
    "4": "4Star",
    "5": "5Star",
  }
  let url =ECOMMERCE[ecommerceName];
  window.location = url;
}
export var enabledCampaigns = () => {
  if(shopAdmin === null){
    getAdminShop();
  }
  //console.log("shopAdmin.enabledCampaigns",shopAdmin.enabledCampaigns);
  return shopAdmin.enabledCampaigns;
}
export var getInfoUrlError = (ecommerceName) =>{
  if ("SHOPIFY" === ecommerceName){
    return getMetafieldMyshopify_domain();
  }

  if ("TIENDANUBE" === ecommerceName){
    return shopAdmin.lang;
  }

  return "";
}

export var isShopify = (shopAdmin) => {
  let shop = getEcommerceByShop(shopAdmin);
  return "SHOPIFY" === shop;
}

export var isTiendaNube = (shopAdmin) => {
  let shop = getEcommerceByShop(shopAdmin);
  return "TIENDANUBE" === shop;
}

export var isVtex = (shopAdmin) => {
  let shop = getEcommerceByShop(shopAdmin);
  return "VTEX" === shop;
}

export var isNotCHECKOUT = (product) => {
  return product.source !== "SHOPIFY_CHECKOUT";
}

export var getEcommerceByShop = (shopAdmin) => {
  /*if ((shopAdmin.shopType === "TIENDANUBE")){// || (shopAdmin.id && shopAdmin.id.startsWith("tn-"))){
    return "TIENDANUBE";
  }
  if ((shopAdmin.shopType === "VTEX")){// || (shopAdmin.id && shopAdmin.id.startsWith("tn-"))){
    return "VTEX";
  }
  return "SHOPIFY";*/
  return shopAdmin.shopType;
}

export var getEcommerce = () => {
  if(shopAdmin === null){
    getAdminShop();
  }
  if(shopAdmin === null){
    return "NULL";
  }
  
  return shopAdmin.shopType;
}

export var getAdminShop = function(){
  if(shopAdmin === null){
    let shop = localStorage.getItem(Constants.storage.USER_SHOP);
    if(shop){
      shopAdmin =  JSON.parse(shop);
      setTimeout(function(){
        window.$crisp.push(["set", "session:data", [
          [
            ["Shop_name", shopAdmin.name],
            ["Shop_url", shopAdmin.domain],
            ["Shop_id", shopAdmin.id],
            ["Shop_email", shopAdmin.email],
            ["Shop_plan", shopAdmin.plan.planType],
            ["Shop_type", shopAdmin.shopType],
            ["Shop_country", shopAdmin.country]
          ]
        ]]);
      }.bind(this), 3000);
      
    }
  }
  return shopAdmin;
}
export var setAdminShop = function(shop){
  shopAdmin = shop;
}

export var getMetafieldMyshopify_domain = function(){
  if(shopAdminmyshopify !== ""){
    return shopAdminmyshopify;
  }
  if(shopAdmin === null){
      getAdminShop();
  }
  
  {shopAdmin && shopAdmin.metafields && Object.keys(shopAdmin.metafields).map(key =>{
    if(shopAdmin.metafields[key].key === "shopify.shop.myshopify_domain"){
      shopAdminmyshopify = shopAdmin.metafields[key].value;
    }
  })};
  return shopAdminmyshopify;
}

export var isChargePerMessage = function(){
  if(ShopChargePerMessage){
    return ShopChargePerMessage;
  }
  if(shopAdmin === null){
      getAdminShop();
  }
  ShopChargePerMessage = false;
  {shopAdmin && shopAdmin.metafields && Object.keys(shopAdmin.metafields).map(key =>{
    if(shopAdmin.metafields[key].key === "shopify.shop.recurring_application_charge_id"){
      ShopChargePerMessage = true;
    }
  })};
  return ShopChargePerMessage;
}

export var getUrl_domain = function(){
  let typeShop = getEcommerce();
  let URL ="";
  if(typeShop === "SHOPIFY"){
    URL = getMetafieldMyshopify_domain();
    URL = "https://admin.shopify.com/store/"+ URL.replace(".myshopify.com","")+"/";

  }

  return URL;
}


export var getShopDomain = function(){
  if(shopURLConfig){
    return shopURLConfig
  }
  if(shopAdmin === null){
    getAdminShop();
  }
  let typeShop = getEcommerce();
  let URL ="";
  if(typeShop === "SHOPIFY"){
    URL = getMetafieldMyshopify_domain();
  }else if(typeShop === "TIENDANUBE"){
    URL = shopAdmin.id.replace('tn-', '');
  }else if(typeShop === "VTEX"){
    URL = shopAdmin.id.replace('vtex-', '');
  }

  shopURLConfig = URL;
  return shopURLConfig;
}

export const DAYSORDERS = {
  "": "never",
  "30": "30day",
  "60": "60day",
}

export const filterInic = {
  fromDate: "",
  toDate: "",
  text: "",
  from: 0,
  size: 20,
  sortBy: "createdAt",
  sortOrder: "desc",
  state:"",
  selectedDate: null
};

export var installTheme20 = function(lang, callback, callbackError){
  const url = window.url_server_2+"updatetheme/?shop="+getShopDomain()+"&aut=a&lang="+lang;
  axios.get(url, {}).then(response => response.data,error => {
    callbackError(error.response);
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

let statusWhatsappSelected = null;

export var getStatusWhatsapp = function(mc, callback) {
  if(statusWhatsappSelected){
    callback(statusWhatsappSelected);
  }else{
    let url = window.url_server_2+"getstatus/?shop="+getShopDomain();
        
    axios.post(url, mc, {}).then(response => response.data,error => {
      return error;
    }).then((data) => {
      if (data){
        console.log(data);
        statusWhatsappSelected= data;
        callback(data);
      }
    });
  }

}


let checkCampaignsSelected = null;
let checkCampaignsSearch = false;
export var checkCampaigns = function(callback, callbackError){
  if(checkCampaignsSelected !== null){
    callback(checkCampaignsSelected);
  }else{
    let campaigns = JSON.parse(localStorage.getItem("campaigns"));
    if(campaigns !== null ){
      checkCampaignsSelected = campaigns;
      callback(checkCampaignsSelected);
    }else{
      if(!checkCampaignsSearch){
        checkCampaignsSearch= true;
        const url = window.url_server_2+"checkCampaigns/?shop="+getShopDomain();
        axios.get(url, {}).then(response => response.data,error => {
          callbackError(error.response);
          }).then((data) => {
            if (data){
              if(data && data.length > 0 && data[0] === "ok"){
                localStorage.setItem("campaigns", JSON.stringify(data));
              }
              checkCampaignsSelected = data;
              checkCampaignsSearch = false;
              callback(data);
            }
        });
      }
    }
  }
}

export var installTheme20New = function(lang, callback, callbackError){
  const url = window.url_server_2+"updateshopify/?shop="+getShopDomain()+"&aut=a&lang="+lang;
  axios.get(url, {}).then(response => response.data,error => {
    callbackError(error.response);
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var updateConfig10 = function(config, callback, callbackError){
  const url = window.url_server_2+"updateConfig/?shop="+getShopDomain();
  config["accessTokenStore"] = Constants.getAccessTokenStore();
  axios.post(url, config, {}).then(response => response.data,error => {
    callbackError(error.response);
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var deleteContent = function(id, callback, callbackError){
  const url = window.url_server_2+"deletecontent/?shop="+getShopDomain()+"&id="+id;
  let config = {};
  config["accessTokenStore"] = Constants.getAccessTokenStore();
  axios.post(url, config, {}).then(response => response.data,error => {
    callbackError(error.response);
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var updateConfigLanguage = function(lang,){
  const url = window.url_server_2+"updateLanguage/?shop="+getShopDomain();
  let config= {};
  config["accessTokenStore"] = Constants.getAccessTokenStore();
  config["lang"] = lang;
  axios.post(url, config, {}).then(response => response.data,error => {
    console.log(error.response);
    }).then((data) => {
      console.log(data);
  });
}

export var getSegments = function(callback, callbackError){
  const url = Constants.URL_API+"marketing/campaigns/segments";//https://api.revie.lat/api/marketing/campaigns/segments
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      callback(data);
  });
}

export var getList = function(callback, callbackError){
  const url = Constants.URL_API+"marketing/audiences";
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      callback(data);
  });
}

export var getSegmentsPHP = function(query, callback, callbackError){
  const url = window.url_server_2+"getsegments/?shop="+getShopDomain();
  let config= {};
  config["accessTokenStore"] = Constants.getAccessTokenStore();
  config["shop"] = getShopDomain();
  config["query"] = query;
  axios.post(url, config, {}).then(response => response.data,error => {
    console.log(error.response);
    callbackError(error.response);
    }).then((data) => {
      console.log(data);
      callback(data);
  });
}

export var getBodyWhatsAppPHP = function(type, query, callback, callbackError){
  const url = window.url_server_2+"getbodywhatsapp/?shop="+getShopDomain();
  let config= {};
  config["accessTokenStore"] = Constants.getAccessTokenStore();
  config["shop"] = getShopDomain();
  config["extra"] = query;
  config["type"] = type;
  axios.post(url, config, {}).then(response => response.data,error => {
    console.log(error.response);
    callbackError(error.response);
    }).then((data) => {
      console.log(data);
      callback(data, type, query);
  });
}

export var getInfoShop = function(callback, callbackError){
  const url = Constants.URL_API+"shops/logged";
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      setError();
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      if (data){
        if (data.lang.includes("-")){
          var lang = data.lang.split("-");
          data.lang = lang[0];
        }
        callback(data);
      }
  });
}


export var getInfoShopAdminPHP = function(lang, callback, callbackError){
  const url = window.url_server_2+"getStore/?shop="+getShopDomain()+"&lang="+lang;
  axios.get(url, {}).then(response => response.data,error => {
      callbackError(error);
      return error;
    }).then((data) => {
      if (data){
        console.log(data);
        callback(data);
      }
  });
}

export var getStorePHP = function(store, callback, callbackError){
  /*const url = "https://tiendanube.triciclogo.com/getshop/";
  let post = {};
  post["shop"]=store.id.replace("tn-", "");
  post["access"]=store.metafields[0].value;
  axios.post(url, post, {}).then(response => response.data,error => {
      callbackError(error);
      return error;
    }).then((data) => {
      if (data){
        console.log(data);
        callback(data);
      }
  });*/

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "shop": store.id.replace("tn-", ""),
    "access": store.metafields[0].value
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  fetch("https://tiendanube.triciclogo.com/getshop/", requestOptions)
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => callbackError(error));
  }

export var getProducts = function(filter, callback, callbackError){
  const url = Constants.URL_API+"products"+Constants.getFilter(filter);
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      setError();
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var newCampaignsTest = function(campaign){
  const url = Constants.URL_API+"marketing/campaigns/test";
  axios.post(url, campaign, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      return error;
    }).then((data) => {
  });
}

export var newCampaigns = function(campaign, callback, callbackError){
  const url = Constants.URL_API+"marketing/campaigns";
  axios.post(url, campaign, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data.id && data.id){
        callback(data);
      }else{
        callbackError(data);
      }
  });
}
export var updateCampaign = function(campaign, callback, callbackError){
  const url = Constants.URL_API+"marketing/campaigns/"+campaign.id;
  axios.put(url, campaign, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data.id && data.id){
        callback(data);
      }else{
        callbackError(data);
      }
  });
}

export var newFlow = function(campaign, callback, callbackError){
  const url = Constants.URL_API+"marketing/campaigns/segments";
  axios.post(url, campaign, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data.id && data.id){
        callback(data);
      }else{
        callbackError(data);
      }
  });
}

export var membersCount = function(id, query, callback, callbackError){
  let segment = {
    "segmentId": id,
    "segmentQuery": query
  };
  const url = Constants.URL_API+"marketing/campaigns/segments/memberscount";
  axios.post(url, segment, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }else{
        callbackError(data);
      }
  });
}




export var getCampaigns = function(filter, callback, callbackError){
  const url = Constants.URL_API+"marketing/campaigns"+Constants.getFilter(filter);
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      setError();
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}
export var deleteCampaigns = function(id, callback, callbackError){
  const url = Constants.URL_API+"marketing/campaigns/"+id;
  axios.delete(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      setError();
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      callback(data);
      
  });
}

export var CheckSegmentDelete = function(id, callback, callbackError){
  const url = Constants.URL_API+"marketing/campaigns?size=1&segmentId="+id;
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      if(data && data.length > 0){
        callbackError(data);
      }else{
        deleteSegment(id, callback, callbackError);
      }
  });
}

export var deleteSegment = function(id, callback, callbackError){
  const url = Constants.URL_API+"marketing/campaigns/segments/"+id;
  axios.delete(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      callback(data);
  });
}

export var getStatisticsCampaigns = function(id, callback, callbackError){
  const url = Constants.URL_API+"marketing/campaigns/"+id+"/statusstatistics";
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      setError();
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var getReviews = function(filter, callback, callbackError){
  const url = Constants.URL_API+"reviews"+Constants.getFilter(filter);
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      setError();
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var getJSStore = function(){
  let url = window.url_server_2+"js/?id="+getShopDomain()+"&store="+getShopDomain()+"&update=true";
  axios.get(url, {}).then(response => response.data,error => {
      console.log(error);
      return error;
    }).then((data) => {
      
  });

  url = window.url_server_2+"css/?id="+getShopDomain()+"&store="+getShopDomain()+"&update=true";
  axios.get(url, {}).then(response => response.data,error => {
      console.log(error);
      return error;
    }).then((data) => {
      
  });
}

export var getJSStoreTiendaNube = function(){
  let url = "https://api.revie.lat/www/config/?type=js&store="+getShopDomain()+"&store="+getShopDomain()+"&update=yes";
  axios.get(url, {}).then(response => response.data,error => {
      console.log(error);
      return error;
    }).then((data) => {
      
  });

  url = "https://api.revie.lat/www/config/?type=css&store="+getShopDomain()+"&store="+getShopDomain()+"&update=yes";
  axios.get(url, {}).then(response => response.data,error => {
      console.log(error);
      return error;
    }).then((data) => {
      
  });
  //https://api.revie.lat/www/config/?type=css&store=1885629&update=yes
}
export var isIA = function(shopJson){
  let isPay = (shopJson && shopJson.plan && shopJson.plan.planType !== "50" && shopJson.plan.planType !== "0" && shopJson.plan.planType !== "200" && shopJson.plan.planType !== "FREE" && shopJson.plan.planType !== "LITE");
  return isPay;
}

export var isGoogleFeed = function(shopJson){
  let isPay = (shopJson && shopJson.plan && (shopJson.plan.planType === "FREE" || shopJson.plan.planType === "LITE" || parseInt(shopJson.plan.planType) < 990));
  return isPay;
}

export var convertMediaRevie = function(url){
  return url.replace("https://revie-prod-images.s3.amazonaws.com/", "https://media.revie.lat/");
}
export var getJSStoreNew = function(lang){
  let url = window.url_server_2+"updateshopify/?shop="+getShopDomain()+"&aut=a&lang="+lang+"&update=1";
  axios.get(url, {}).then(response => response.data,error => {
      console.log(error);
      return error;
    }).then((data) => {
      
  });
}

export var getStatestatistics = function(filter, callback, callbackError){
  const url = Constants.URL_API+"reviews/statestatistics"+Constants.getFilter(filter);
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      setError();
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var getStarstatistics = function(filter, callback, callbackError, isfilter=true){
  const url = Constants.URL_API+"reviews/starstatistics"+(isfilter ? Constants.getFilter(filter) : filter);
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      setError();
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var setReview = function(callback, callbackError, review){
  const url = Constants.URL_API+"reviews/"+review.id;
  axios.put(url, review,Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var setInfoShop = function(callback, callbackError, shop){
  const url = Constants.URL_API+"shops/"+shop.id;
  axios.put(url, shop, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var getDiscount = function(callback, callbackError){
  const url = Constants.URL_API+"shopify/permissions?permission=price_rules";
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var getWriteCustomers = function(callback, callbackError){
  const url = Constants.URL_API+"shopify/permissions?permission=customers";
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var getUtmStatistics = function(callback, callbackError, addUrl="?utmSource=revie"){
  const url = Constants.URL_API+"orders/utmstatistics"+addUrl;
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var getStatistics = function(callback, callbackError, urlApi){
  const url = Constants.URL_API+urlApi;
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      //Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var getSegmentsCount = function(callback, callbackError, segmentId){
  const url = Constants.URL_API+`marketing/campaigns/segments/${segmentId}/memberscount`;
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export var getProduct = function(filter, callback, callbackError){
  const url = Constants.URL_API+`shopify/products?query=`+filter;
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

let searchContentSid = false;
export var getContentSid = (textAdd, callback, callbackError) => {
  if(!searchContentSid){
    if((window.contentSid === null || textAdd !== "")){
      getContentSidServer(textAdd, callback, callbackError);
    }else{
      callback(window.contentSid);
    }
  }
}

export var  getContentSidServer = (textAdd, callback, callbackError) => {
  searchContentSid = true;
  const url = window.url_server_2+"getcontent/?shop="+getShopDomain()+textAdd;
  axios.get(url, {}).then(response => response.data,error => {
      callbackError(error);
      return error;
    }).then((data) => {
      if (data){
        window.contentSid = data;
        callback(data);
        searchContentSid = false;
      }
  });
}

export var  getContentSidExamplesServer = (textAdd, callback, callbackError) => {
  const url = window.url_server_2+"getcontentexample/?shop="+getShopDomain()+textAdd;
  axios.get(url, {}).then(response => response.data,error => {
      callbackError(error);
      return error;
    }).then((data) => {
      if (data){
        callback(data);
      }
  });
}
let searchCurrency = false;
export var getCurrency = (callback) => {
  if(!searchCurrency){
    if(window.currency === null){
      getCurrencyServer(callback)
    }else{
      console.log("window.currency", window.currency);
      callback(window.currency);
    }
  }
}
var getCurrencyServer = (callback) => {
  searchCurrency = true;
  const url = window.url_server_2+"getcurrency/";
  axios.get(url, {}).then(response => response.data,error => {
      return error;
    }).then((data) => {
      if (data){
        window.currency = data;
        callback(data);
        searchCurrency = false;
      }
  });
}


export var uploadFile = function(file, fileName, id, callback, callbackError){
  const formdata = new FormData();
  formdata.append("file", file, fileName);


  const url = Constants.URL_API+"marketing/audiences/"+id+"/importcsv";
  axios.post(url, formdata, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      callback(data);
      
  });
}

export var createAudiences = function(name, total, callback, callbackError, file){
  const url = Constants.URL_API+"marketing/audiences";
  let data = {
    "name": name,
    "type":"CSV",
    "total": total
 };
 
  axios.post(url, data, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data && data.id && file){
        uploadFile(file, file.name, data.id, callback, callbackError); 
        //callback(data);
      }
  });
}


export var exportCVS = function(callback, callbackError){
  const url = Constants.URL_API+"reviews/report/";
  axios.post(url, {}, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error);
      Constants.setErrors(error);
      callbackError(error.response);
      return error;
    }).then((data) => {
      console.log(data);
      if (data){
        callback(data);
      }
  });
}

export const setLanguage = (Shop, updateServer = false) => {
  if (Shop.lang && (Shop.lang === 'es' || Shop.lang === 'en' || Shop.lang === 'pt' )){
    window.paramRevie.language = Shop.lang;
    if(updateServer) {
      updateConfigLanguage(Shop.lang);
    }
    document.documentElement.setAttribute("lang", Shop.lang);
  }
}

export const getUrlImageWidth = (image) =>{
  if(image.includes("shopify")){
    if(image.includes("?")){
      return image + "&width=130";
    }else{
      return image + "?width=130";
    }
  }
  return image;
}

export const isLengthSpace = (text) => {
  var regex = /[ ]/g; 

  return text && text!= '' && text.match(regex) && text.match(regex).length > 2;
}

export const hexToRgb = (hex) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? parseInt(result[1], 16)+","+parseInt(result[2], 16)+","+parseInt(result[3], 16) : null;
}
export const addDays = (days, date = null) => {
  var d = null;
  if(date){ 
    d = date;
  }else{
    d=new Date();
  }
  d.setDate(d.getDate() + days);
  var ret = '';
  if (window.paramRevie.language == 'en'){
    ret = d.getFullYear().toString()+"/"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"/"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString());
  }else{
    ret = (d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString()) + "/"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString()) + "/"+d.getFullYear().toString();
  }
  return ret;
}

export const getMessage = (message) =>{
  let element = null;
  try{
    if (window.paramRevie.language === null){
      window.paramRevie.language = 'es';
      var shop = getAdminShop();
      if (shop){
        window.paramRevie.language = shop.lang
      }
    }

    let textJson = getlanguage(window.paramRevie.language);
    element = textJson[message];
  } catch(e){
    console.log(e);
  }
  if (element){
    return element;
  }else return message;
}

let languageSelected = null;
let languageSelectedSearch = false;
export var getlanguage = function(language){
  if(languageSelected !== null && languageSelected.language === language){
    return languageSelected;
  }else{
  let languageJson = JSON.parse(localStorage.getItem("language_"+language));
   if(languageJson !== null && languageJson.language === language && languageJson.version === window.languagesVersion){
      languageSelected = languageJson;
      return languageJson;
    }else{
      if(!languageSelectedSearch){
        languageSelectedSearch= true;
        const url = "/languages/"+language+".json?version="+window.languagesVersion;
        axios.get(url, {}).then(response => response.data,error => {
            console.log(error);
            return error;
          }).then((data) => {
            console.log(data);
            data.version = window.languagesVersion;
            localStorage.setItem("language_"+language, JSON.stringify(data));
            languageSelected = data;
            languageSelectedSearch = false;
            return data;
        });
      }
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////

export var configAdmin= {
  "initialQuota": {
    type: "string",
    placeholder: "0 a infinito",
    definedText: "Saldo promocional"
  },
}

export var getPersonalizad=function(personalizad){

  personalizad.weight=Math.trunc(personalizad.weight);
  if (personalizad.weight < 1){
    personalizad.weight = 1;
  }
  personalizad.length=Math.trunc(personalizad.length);
  personalizad.height=Math.trunc(personalizad.height);
  personalizad.width=Math.trunc(personalizad.width);
  return personalizad;
}

export var isStagesShow=function(shop){
  return true;
};

export var isDev=function(){
  return false;
};

export var isShow=function(shop, type){
  if (type === "stages"){
    return isStagesShow(shop);
  }else if (type === "isSeeBalance"){
    return isSeeBalance();
  }
  return false;
};


export var isSeeBalance = () =>{
  //console.log(Constants.initialState.config.isSeeBalance);
  try{
    return Constants.initialState.config.isSeeBalance;
  }catch(e){
    console.log(e);
  }
  return false;
  
}


export var updateImage = (id) => {
  let retrievedObject = localStorage.getItem(id);
  if (retrievedObject){
    console.log("ERROR: imagen sin actualizar");
  }else{
    localStorage.setItem(id, JSON.stringify(true));
    const url = Constants.URL_API+"products/"+id+"/refresh";
    axios.post(url, {}, Constants.getHeaders()).then(response => response.data,error => {
        console.log(error);
        Constants.setErrors(error);
        return error;
      }).then((data) => {
        console.log(data);
    });
  }
}


export var printPage = function(timerPrint=500, timerBody=1500){

  setTimeout(function(){
      window.print();
  }.bind(this), timerPrint);

  setTimeout(function(){
    document.body.style.overflowY = "scroll";
  }.bind(this), timerBody);
  return "";
}

export var addScrollBody = function(timerBody=1500){
  setTimeout(function(){
    document.body.style.overflowY = "auto";
  }.bind(this), timerBody);
}

export var getShopInfo = function(shop, index, key){

  if(shop[index]){
    return shop[index][key];
  }
  return "";
}

export var getShop = function(shop, index){

  for (var i in shop) {
    let element = shop[i];
    if (element && element.id === index){
      return element;
    }
  }
  return null;
}

export var getListCustomerId = function(shop){

  let list_shop=[];
  for (var i in shop) {
    let element = shop[i];
    list_shop[element.id] = element;
  }
  return list_shop;
}

export var getDataShop = function(shop, list, index, key){
    try{
      if (index >= 0){
        return list[index][key];
      }else{
        return shop[key];
      }
    }catch (e){
      console.log(e);
    }
    return "";
}


export var editShop = function(shop, callback, callbackError) {
  const url = Constants.URL_API+"customers/"+shop.id;
  
  axios.put(url, shop, Constants.getHeaders()).then(response => response.data,error => {
      console.log(error.response);
      if (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404) {
        callbackError(Constants.getMessage(error.response.data.message));
      }
      return error;
    }).then((data) => {
        callback(data);
    });

}

export var loadDataUsers = function(dispatch, isGoHome) {
  const url = Constants.URL_API+"customers/users/logged";
    axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
      Constants.setErrors(error);
      if (error.response.status === 400) {
        let retrievedObject = localStorage.getItem(Constants.storage.SHOP);
          console.log(retrievedObject);
          if (retrievedObject === '""' || retrievedObject === null){
            window.location.href = "/preferences";
          }
      }
      return error;
    })
    .then((data) => {
        console.log("customers/users/logged");
        console.log(data);
        dispatch({
          type: Constants.types.LOGIN_LOCAL,
          data: data
        });
        if (data === ""){
          console.log("Entro");
          let retrievedObject = localStorage.getItem(Constants.storage.SHOP);
          console.log(retrievedObject);
          if (retrievedObject === '""' || retrievedObject === null){
            window.location.href = "/preferences";
          }
        }else if (data.id){
          if (data.permissions && data.permissions[0] === "read:customers"){
            dispatch({
              type: Constants.types.LOGIN_TYPE,
              data: "ADMIN"
            });
            if (isGoHome){
              window.location.href = '/home';
            }
          }else{
            loadDataShop(dispatch, isGoHome);
          }
        }

    }).catch(error => {
      console.log(error);
    });
}

export var loadDataShop = function(dispatch, isGoHome) {
  const url = Constants.URL_API+"customers/loggeds";
  axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
    Constants.setErrors(error);
    return error;
  })
  .then((data) => {
      console.log(data);
      if (data){
        dispatch({
          type: Constants.types.LOGIN_SHOP,
          data: data
        });
        dispatch({
          type: Constants.types.LOGIN_TYPE,
          data: data.type
        });
        if (isGoHome){
          window.location.href = '/home' ;
        }
      }
  }).catch(error => {
    console.log(error);
  });
}

export var validateMax = function(arrayList, max) {
  for (var i in arrayList) {
    let element = arrayList[i];
    if (element && element.length > max){
      return true;
    }
  }
  return false;
}

export var textSubString = function(text, min, max) {
  try{
    return text.substr(min, max);
  }catch(e){
    console.log(3);
    return text;
  }
}

class General extends Component {
}
const mapStateToProps = (state) => ({
    empaques: state.packageList,
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export const yesNo = {
  "true": "true", 
  "false": "false"
};

export const yesNoForwardReview = {
  "true": "trueForwardReview", 
  "false": "falseForwardReview"
};


export const valueType= {
  "PERCENTAGE": "PERCENTAGE", 
  "FIXED_AMOUNT": "FIXED_AMOUNT"
};

export const pagination = {
    10: "10", 
    15: "15",
    20: "20",
    30: "30", 
    50: "50",
    100: "100",
    150: "150"
};

export const STATE_RETURNS = {
  PENDING:"Pendiente", 
  APPROVED: "Aprobada", 
  REJECTED: "Rechazada",
}

export const LOCAL_STATE = {
  MEX: "MEX",
  DF: "DF"
}

export const FLOW_CAMPAIGNS = {
  "Winback_Customers":{
    "name": "Winback_Customers",
    "title": "WC.title",
    "description": "WC.description",
    "query": "number_of_orders >= [X] AND (last_order_date BETWEEN -[Y]d AND -[B]d)",
    "text_config": "WC.text_config",
    "config": {
      "X": {
        "label": "WC.X.label",
        "type": "input",
        "sub_type": "number",
        "required": true,
        "min": 1,
        "max": 100,
        "step": 1,
        "value": 5,
        "postfix": "WC.X.postfix"
      },
      "Y":{
        "label": "WC.Y.label",
        "type": "select",
        "required": true,
        "options": [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 285, 300, 315, 330, 345, 360],
        "value": 180,
        "postfix": "WC.Y.postfix",
        "subtract": {
          "B": 1
          }
      } 
    }
  },
  "Carritos_abandonados":{
    "name": "Carritos_abandonados",
    "title": "CA.title",
    "description": "CA.description",
    "query": "abandoned_checkout_date = today OR number_of_orders = 11[X] AND (last_order_date <= -[Y]d OR last_order_date IS NULL)",
    "text_config": "CA.text_config",
    "config": {
      "X": {
        "label": "CA.X.label",
        "type": "select",
        "sub_type": "valuesIn",
        "defaultText": "CA.X.defaultText",
        "convertValue": true,
        "required": true,
        "options": [{
          "value": "1",
          "label": "1_H",
          "extra":{"Y": "1"},
          "data":{"campaing":{"interval": "HOUR", "source": "SHOPIFY_CHECKOUT"},"campaignData":{"additionalData":{"checkout.hours.notify":"1"},"contentVariables":{"1":"customer_first_name","2":"abandoned_checkout_url"}},"extraURL":"https://revie.cc/{{2}}", "nohour":true, "noshowinformation":true}
        },{
          "value": "2",
          "label": "2_H",
          "extra":{"Y": "2"},
          "data":{"campaing":{"interval": "HOUR", "source": "SHOPIFY_CHECKOUT"},"campaignData":{"additionalData":{"checkout.hours.notify":"2"},"contentVariables":{"1":"customer_first_name","2":"abandoned_checkout_url"}},"extraURL":"https://revie.cc/{{2}}", "nohour":true, "noshowinformation":true}
        },{
          "value": "3",
          "label": "3_H",
          "extra":{"Y": "3"},
          "data":{"campaing":{"interval": "HOUR", "source": "SHOPIFY_CHECKOUT"},"campaignData":{"additionalData":{"checkout.hours.notify":"3"},"contentVariables":{"1":"customer_first_name","2":"abandoned_checkout_url"}},"extraURL":"https://revie.cc/{{2}}", "nohour":true, "noshowinformation":true}
        },{
          "value": "6",
          "label": "6_H",
          "extra":{"Y": "6"},
          "data":{"campaing":{"interval": "HOUR", "source": "SHOPIFY_CHECKOUT"},"campaignData":{"additionalData":{"checkout.hours.notify":"6"},"contentVariables":{"1":"customer_first_name","2":"abandoned_checkout_url"}},"extraURL":"https://revie.cc/{{2}}", "nohour":true, "noshowinformation":true}
        },{
          "value": "9",
          "label": "9_H",
          "extra":{"Y": "9"},
          "data":{"campaing":{"interval": "HOUR", "source": "SHOPIFY_CHECKOUT"},"campaignData":{"additionalData":{"checkout.hours.notify":"9"},"contentVariables":{"1":"customer_first_name","2":"abandoned_checkout_url"}},"extraURL":"https://revie.cc/{{2}}", "nohour":true, "noshowinformation":true}
        },{
          "value": "12",
          "label": "12_H",
          "extra":{"Y": "12"},
          "data":{"campaing":{"interval": "HOUR", "source": "SHOPIFY_CHECKOUT"},"campaignData":{"additionalData":{"checkout.hours.notify":"12"},"contentVariables":{"1":"customer_first_name","2":"abandoned_checkout_url"}},"extraURL":"https://revie.cc/{{2}}", "nohour":true, "noshowinformation":true}
        },{
          "value": "24",
          "label": "1_D",
          "extra":{"Y": "24"},
          "data":{"campaing":{"interval": "HOUR", "source": "SHOPIFY_CHECKOUT"},"campaignData":{"additionalData":{"checkout.hours.notify":"24"},"contentVariables":{"1":"customer_first_name","2":"abandoned_checkout_url"}},"extraURL":"https://revie.cc/{{2}}", "nohour":true, "noshowinformation":true}
        },{
          "value": "48",
          "label": "2_D",
          "extra":{"Y": "48"},
          "data":{"campaing":{"interval": "HOUR", "source": "SHOPIFY_CHECKOUT"},"campaignData":{"additionalData":{"checkout.hours.notify":"48"},"contentVariables":{"1":"customer_first_name","2":"abandoned_checkout_url"}},"extraURL":"https://revie.cc/{{2}}", "nohour":true, "noshowinformation":true}
        },{
          "value": "72",
          "label": "3_D",
          "extra":{"Y": "72"},
          "data":{"campaing":{"interval": "HOUR", "source": "SHOPIFY_CHECKOUT"},"campaignData":{"additionalData":{"checkout.hours.notify":"72"},"contentVariables":{"1":"customer_first_name","2":"abandoned_checkout_url"}},"extraURL":"https://revie.cc/{{2}}", "nohour":true, "noshowinformation":true}
        }],
        "value": "",
        "postfix": "CA.X.postfix"
      },
      "Y":{"value": "1",}
    }
  },
  "Reviews":{
    "name": "Reviews",
    "title": "R.title",
    "description": "R.description",
    "query": "customer_tags CONTAINS 'Revie [X] STAR review'",
    "text_config": "R.text_config",
    "config": {
      "X": {
        "label": "R.X.label",
        "type": "select",
        "required": true,
        "options": [1,2,3,4,5],
        "value": 5,
        "postfix": "R.Y.postfix"
      }
    }
  },
  "Browse_abandonment":{
    "name": "Browse_abandonment",
    "title": "BA.title",
    "description": "BA.description",
    "query": "storefront.product_viewed(since: [X]) = true AND orders_placed(since: -30d) = false AND (abandoned_checkout_date IS NULL OR abandoned_checkout_date <= -30d)",
    "text_config": "BA.text_config",
    "config": {
      "X": {
        "label": "BA.X.label",
        "required": true,
        "type": "select",
        "sub_type": "valuesIn",
        "defaultText": "BA.X.defaultText",
        "convertValue": true,
        "required": true,
        "options": [{
          "value": "today",
          "label": "1_H",
          "data":{"campaing":{"interval": "HOUR"}, "nohour":true}
        },{
          "value": "-1d, until: -0d",
          "label": "1_D",
          "data":{"campaing":{"interval": "DAY"}, "nohour":true}
        },{
          "value": "-2d, until: -1d",
          "label": "2_D",
          "data":{"campaing":{"interval": "DAY"}, "nohour":true}
        },{
          "value": "-3d, until: -2d",
          "label": "3_D",
          "data":{"campaing":{"interval": "DAY"}, "nohour":true}
        },{
          "value": "-4d, until: -3d",
          "label": "4_D",
          "data":{"campaing":{"interval": "DAY"}, "nohour":true}
        },{
          "value": "-5d, until: -4d",
          "label": "5_D",
          "data":{"campaing":{"interval": "DAY"}, "nohour":true}
        }],
        "value": "",
        "postfix": "CA.X.postfix"
      }
    }
  },
  "Product_Follow_up":{
    "name": "Product_Follow_up",
    "title": "PFU.title",
    "description": "PFU.description",
    "query": "products_purchased([X]) = true AND (last_order_date BETWEEN -[Y]d AND -[Z]d)",
    "text_config": "PFU.text_config",
    "tabs": [
      {
        "name": "PFU.tab1",
        "config": {
          "X": {
            "label": "PFU.X.label",
            "required": true,
            "type": "input",
            "sub_type": "listproduct",
            "value": "",
            "convertValue": true,
            "postfix": "PFU.X.postfix",
            "inic": "id: (",
            "finish": ")"
          },
          "Y": {
            "label": "PFU.Y.label",
            "required": true,
            "type": "input",
            "sub_type": "number",
            "min": 1,
            "max": 360,
            "step": 1,
            "value": 5,
            "postfix": "PFU.Y.postfix",
            "subtract": {
              "Z": 1
              }
          }
        }
      },{
        "name": "PFU.tab2",
        "config": {
          "X": {
            "label": "PFU.X_1.label",
            "required": true,
            "type": "input",
            "sub_type": "text",
            "value": "",
            "postfix": "PFU.X_1.postfix",
            "inic": "tag: '",
            "finish": "'"
          },
          "Y": {
            "label": "PFU.Y.label",
            "required": true,
            "type": "input",
            "sub_type": "number",
            "min": 1,
            "max": 360,
            "step": 1,
            "value": 5,
            "postfix": "PFU.Y.postfix",
            "subtract": {
              "Z": 1
              }
          }
        }
      }],
    "config": {}
  },
  "Welcome_Series":{
    "name": "Welcome_Series",
    "title": "WS.title",
    "description": "WS.description",
    "query": "customer_tags CONTAINS 'Revie Opt-In'",
    "text_config": "WS.text_config",
    "config": {
    }
  }
}

export const HEARINGS = {
  "Potential_Purchasers":{
    "name": "Potential_Purchasers",
    "title": "PP.title",
    "description": "PP.description",
    "query": "number_of_orders = 0",
    "text_config": "PP.text_config",
    "config": {}
  },
  "Repeat_Buyers":{
    "name": "Repeat_Buyers",
    "title": "RB.title",
    "description": "RB.description",
    "query": "number_of_orders >= 1",
    "text_config": "RB.text_config",
    "config": {}
    },
  "VIP_Customers":{
    "name": "VIP_Customers",
    "title": "VC.title",
    "description": "VC.description",
    "query": "number_of_orders >= 3",
    "text_config": "VC.text_config",
    "config": {}
  },
  "WinBack_Opportunities":{
    "name": "WinBack_Opportunities",
    "title": "WO.title",
    "description": "WO.description",
    "query": "orders_placed(count_at_least: [X]) = true AND last_order_date <= -[Y]d",
    "text_config": "WO.text_config",
    "config": {
      "X": {
        "label": "WO.X.label",
        "type": "input",
        "sub_type": "number",
        "required": true,
        "min": 1,
        "max": 31,
        "step": 1,
        "value": 1,
        "postfix": "WO.X.postfix"
      },
      "Y": {
        "label": "WO.Y.label",
        "required": true,
        "type": "input",
        "sub_type": "number",
        "min": 1,
        "max": 360,
        "step": 1,
        "value": 180,
        "postfix": "WO.Y.postfix",
      }
    }
  }
}

export const CONTENTSIDIA = [
  {
    "name": "Carritos_abandonados",
    "title": "IA.CA.title",
    "description": "IA.CA.description",
    "query": "abandoned_checkout_date = [X] AND (last_order_date <= -[Y]d OR last_order_date IS NULL)",
    "text_config": "CA.text_config",
    "config": {}
  },{
    "name": "Promote_product",
    "title": "IA.PP.title",
    "description": "IA.PP.description",
    "query": "IA.PP.query",
    "text_config": "R.text_config",
    "config": {
      "X": {
            "label": "PFU.X.label",
            "required": true,
            "type": "input",
            "sub_type": "listproduct",
            "value": "",
            "postfix": "PFU.X.postfix",
        },
        "Y": {
          "label": "IA.OT.X.label",
          "required": true,
          "type": "input",
          "sub_type": "text",
          "value": "",
        }
    }
  },{
    "name": "Limited_offer",
    "title": "IA.OT.title",
    "description": "IA.OT.description",
    "sub_title": "IA.OT.sub_title",
    "query": "IA.OT.query",
    "text_config": "BA.text_config",
    "config": {
      "X": {
        "label": "IA.OT.X.label",
        "required": true,
        "type": "input",
        "sub_type": "text",
        "value": "",
      }
    }
  },{
    "name": "repeat_customers",
    "title": "IA.RC.title",
    "description": "IA.RC.description",
    "query": "IA.RC.query",
    "text_config": "BA.text_config",
    "config": {
      "X": {
        "label": "IA.P.X.label",
        "required": true,
        "type": "input",
        "sub_type": "text",
        "value": "",
      }}
  },{
    "name": "Personalized",
    "title": "IA.P.title",
    "description": "IA.P.description",
    "query": "IA.P.query",
    "text_config": "BA.text_config",
    "sub_title": "IA.P.sub_title",
    "config": {
      "X": {
        "label": "IA.P.X.label",
        "required": true,
        "type": "input",
        "sub_type": "text",
        "value": "",
      }
    }
  }
];

export var isLocalShipping = function(orden,shop) {
  //const state =  getDataShop(shop, shop.addresses ,-1, "state")
    // return (orden.shippingAddress.provinceCode === state)|| (orden.shippingAddress.provinceCode === "MEX");
     return (orden.shippingAddress.provinceCode === LOCAL_STATE.DF )|| (orden.shippingAddress.provinceCode === LOCAL_STATE.MEX);
};
// `connect` returns a new function that accepts the component to wrap:

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(General);
