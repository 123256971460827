import React, { Component } from 'react';
import * as Constants from './../../store/index';
import Axios from 'axios';
import {SUCCESS} from '../../components/svg/animate';

import * as Util from './../../data/General';
import Button from './Button';


class UploadImages extends Component {

    state = { 
        selectedFile: null, 
        dataImageNew : null,
        isSearch: false,
        type: "image",
        format: "",
        id: "file"+Math.floor(Math.random() * 999),
        errorText: "",
    };

    dropZone = () =>{
        const { id } = this.state;
        const dropZone = document.getElementById("label-file"+id);
        if(dropZone){
            dropZone.addEventListener('dragover', (event) => {
                event.preventDefault();
                dropZone.style.backgroundColor = '#f0f0f0';
            });

            dropZone.addEventListener('dragleave', () => {
                dropZone.style.backgroundColor = '';
            });

            dropZone.addEventListener('drop', (event) => {
                event.preventDefault();
                dropZone.style.backgroundColor = '';
                const files = event.dataTransfer.files;
                console.log('Archivos arrastrados:', files);
                this.onFileChange(files[0])
            });
        }
    }

    componentDidMount() {
        this.dropZone();
    }


    // On file select (from the pop up)
    onFileChange = async file => {

        this.setState({ selectedFile: file });
        var reader = new FileReader();
        let format = "";
        let type = "";
        const promise = new Promise(resolve => {
            reader.onload = async function (e) {
                this.setState({dataImageNew : e.target.result});
                var match = e.target.result.match(/^data:([^/]+)\/([^;]+);/) || [];
                type = match[1];
                format = match[2];
                this.setState({type : type, format: format});
                resolve();
            }.bind(this);
        });
        
        reader.readAsDataURL(file);
        await promise;
        
        const objectURL = URL.createObjectURL(file);
        let newelement = 'img';
        if(type === "video"){
            newelement = 'video';
        }
        const img = document.createElement(newelement);
        let width = 0;
        let height = 0;
        
        if(type === "video"){
            var sourceElement = document.createElement('source');
            sourceElement.setAttribute('src', objectURL);
            sourceElement.setAttribute('type', 'video/mp4');

            // Append the source to the video element
            img.appendChild(sourceElement);
            setTimeout(function(){
                this.setState({errorText: ""});
                this.onFileUpload();
            }.bind(this), 1500);
        }else{
            img.src = objectURL;
            img.onload = function() {
                width = img.width;
                height = img.height;
                URL.revokeObjectURL(objectURL); // Clean up
    
                const { minWidth=0, minHeight=0, square=false, formatcontrol=false } = this.props;
                let opload = false;
                if(minWidth === 0  && minHeight === 0 && !square){
                    opload = true;
                }else{
                    if(minWidth !== 0  && minHeight !== 0 && width < minWidth && height < minHeight){
                        this.setState({errorText: Util.getMessage("logo_error_1") + minWidth+" x "+minHeight+"px",});
                    }else if(square && width !== height){
                        this.setState({errorText: Util.getMessage("logo_error_2")});
                    }else if(formatcontrol && !formatcontrol.includes(format.toUpperCase())){
                        this.setState({errorText: Util.getMessage("logo_error_3")+formatcontrol});
                    }else{
                        opload = true;
                    }
                }
    
                if(opload){
                    setTimeout(function(){
                        this.setState({errorText: ""});
                        this.onFileUpload();
                    }.bind(this), 1500);
                }  else{
                    setTimeout(function(){
                        document.getElementById(this.state.id).value = "";
                        this.setState({isSearch: false, imageuploadOk: false, selectedFile: null, dataImageNew : null});
                        this.chance();
                    }.bind(this), 2000);
                }  
    
    
            }.bind(this);
        }
            
      };
      
      // On file upload (click the upload button)
      onFileUpload = () => {
        const { customerId, upload, onBlur, name } = this.props;
        this.setState({isSearch: true});
        // Create an object of formData
        const formData = new FormData();
      
        // Update the formData object
        formData.append(
            "file",
            this.state.selectedFile,
            this.state.selectedFile.name,        
        );
      
        // Details of the uploaded file
        //console.log(this.state.selectedFile);
      
        // Request made to the backend api
        // Send formData object
        const url = Constants.URL_API+"marketing/campaigns/uploadimage";
        //Axios.post(url, formData);

        Axios.post(url, formData, Constants.getHeaders()).then(response => response.data,error => {
            if (error.response.status === 401 || error.response.status === 400 || error.response.status === 403  || error.response.status === 404) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), isSearch: false});
            }
            return error;
          }).then((data) => {
            //console.log(data);    
            if (upload){
                upload(data);
            }    
            if(onBlur){
                onBlur(data, name);
                this.setState({ imageuploadOk: true });
            }  
            setTimeout(function(){
                this.setState({isSearch: false, imageuploadOk: false, selectedFile: null, dataImageNew : null});//dataImageNew : null
              }.bind(this), 5000);
            
        });
      };

      chance = () => {
        const { onBlur, name } = this.props;
        if(onBlur){
            onBlur("", name);
        }  
        setTimeout(function(){this.dropZone();}.bind(this), 2000);
      }

      errorUpload = (e) => {
        console.log(e);
        console.log(e.type);
      }

    render() {
        const { imageuploadOk, dataImageNew, isSearch, type, id, errorText } = this.state;
        const { value, isPreview = false, text_btn="btn_change", disabled=false } = this.props;

        return (
            <div className="content-upload-image">
                <div>
                    {imageuploadOk ? (
                        <div className='title content-flex space-between space-top'>
                            <div className='content-flex flex-start' style={{gap: "1rem"}}>
                                <div dangerouslySetInnerHTML={{__html: SUCCESS}} /> {Util.getMessage("successe_upload_image")}
                            </div>
                        </div>
                    ) : (value && value !== "") ? 
                    <div className='content-flex flex-start' style={{gap: "1rem"}}>
                        {isPreview ? <img src={value} alt="preview" /> : null}
                        <Button 
                            value={Util.getMessage(text_btn)} 
                            type="primary" 
                            onClick={() => this.chance()}/>
                    </div> 
                    : dataImageNew ? (
                        <div>
                            {type === "image" ? (
                                <img src={dataImageNew} alt="preview" onError={(e) => {this.errorUpload(e)}}/>
                            ) : type === "video" ? (
                                <video style={{width: "200px"}} autoPlay="1" muted><source src={dataImageNew} type="video/mp4"/></video>
                            ) : null}
                            
                        </div>
                    ) : (
                        <label htmlFor={id} id={"label-file"+id} className={`label-file ${disabled ? "disabled" : ""}`}	>
                            <div>
                                <span className="formbold-drop-file"> {Util.getMessage("DropFile")}</span>
                                <span className="formbold-or"> O </span>
                                <span className="formbold-browse"> {Util.getMessage("SelectFile")} </span>
                            </div>
                        </label>
                    )}
                    <input type="file" id={id} style={{"display": "none"}} multiple accept="image/*, video/*" onChange={(event) => this.onFileChange(event.target.files[0])}/>
                </div>
                {false && (!imageuploadOk && !(value && value !== "") )? (
                <Button 
                        value={Util.getMessage("btn_upload_image")} 
                        disabled={!dataImageNew || isSearch} 
                        type="primary" 
                        onClick={() => this.onFileUpload()}/>
                ) : null}
                {(errorText !== "") && (<div className="error">{errorText}</div>)}
            </div>
        );
  }
}

export default UploadImages;