import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Util from './../data/General';
import Page from '../components/html/Page';
import * as Constants from './../store/index';

import mixpanel from 'mixpanel-browser';
import ListCampaigns from '../components/widget/ListCampaigns';
import Button from '../components/html/Button';
import InputHtml from '../components/html/InputHtml';
import Notify from '../components/html/Notify';
import ContentSid from './ContentSid';
import ListSegments from '../components/html/ListSegments';
import WhatsApp from '../components/html/WhatsApp';

import axios from "axios";
import CurrencyFormat from 'react-currency-format';
import Step from '../components/html/step';
import {SUCCESS} from '../components/svg/animate';
import SelectHtml from '../components/html/SelectHtml';
import RememberProm from '../components/html/RememberProm';
import Filter from '../components/widget/Filter';
const COST = {"MX":0.052,"CL":0.107,"CO":0.02,"PE":0.084,"ES":0.074,"US":0.03,"CA":0.03,"BR":0.075,"DE":0.164,"IL":0.042,"NG":0.062,"TR":0.013,"SA":0.049,"FR":0.172,"IT":0.083,"GB":0.085,"AR":0.074,"EG":0.129,"IN":0.012,"MY":0.103,"PK":0.057,"RU":0.096,"AE":0.041,"ID":0.049,"NL":0.192,"ZA":0.045,"BO":0.089,"CR":0.089,"DO":0.089,"EC":0.089,"SV":0.089,"GT":0.089,"HT":0.089,"HN":0.089,"JM":0.089,"NI":0.089,"PA":0.089,"PY":0.089,"PR":0.089,"UY":0.089,"VE":0.089,"AF":0.088,"AU":0.088,"BD":0.088,"KH":0.088,"CN":0.088,"HK":0.088,"JP":0.088,"LA":0.088,"MN":0.088,"NP":0.088,"NZ":0.088,"PG":0.088,"PH":0.088,"SG":0.088,"LK":0.088,"TW":0.088,"TJ":0.088,"TH":0.088,"TM":0.088,"UZ":0.088,"VN":0.088,"BH":0.041,"IQ":0.041,"JO":0.041,"KW":0.041,"LB":0.041,"OM":0.041,"QA":0.041,"YE":0.041,"AL":0.103,"AM":0.103,"AZ":0.103,"BY":0.103,"BG":0.103,"HR":0.103,"CZ":0.103,"GE":0.103,"GR":0.103,"HU":0.103,"LV":0.103,"LT":0.103,"MK":0.103,"MD":0.103,"PL":0.103,"RO":0.103,"RS":0.103,"SK":0.103,"SI":0.103,"UA":0.103,"DZ":0.027,"AO":0.027,"BJ":0.027,"BW":0.027,"BF":0.027,"BI":0.027,"CM":0.027,"TD":0.027,"CG":0.027,"ER":0.027,"ET":0.027,"GA":0.027,"GM":0.027,"GH":0.027,"GW":0.027,"CI":0.027,"KE":0.027,"LS":0.027,"LR":0.027,"LY":0.027,"MG":0.027,"MW":0.027,"ML":0.027,"MR":0.027,"MA":0.027,"MZ":0.027,"NA":0.027,"NE":0.027,"RW":0.027,"SN":0.027,"SL":0.027,"SO":0.027,"SS":0.027,"SD":0.027,"SZ":0.027,"TZ":0.027,"TG":0.027,"TN":0.027,"UG":0.027,"ZM":0.027,"AT":0.071,"BE":0.071,"DK":0.071,"FI":0.071,"IE":0.071,"NO":0.071,"PT":0.071,"SE":0.071,"CH":0.04};

const STEPS = [
  {
    id: 0,
    content: Util.getMessage("step_1"),
    loader: false,
    check: false,
    error: false,
    message: null
  },
  {
    id: 1,
    content: Util.getMessage("step_2"),
    loader: false,
    check: false,
    error: false,
    message: null
  },
  {
    id: 2,
    content: Util.getMessage("step_3"),
    loader: false,
    check: false,
    error: false,
    message: null
  }
]

class Campaigns extends Component {

    state = {
      reviews: null,
      reviewsList: null,
      filterState: null,
      isSearch: false,
      tabSelect: null,
      shop: null,
      stars: "",
      isPay: false,
      isShowProduct: false,
      timeOut:null,
      isNew: false,
      newCampaing: null,
      notify: "",
      enabledPriceRules: true,
      details: false,
      wa: null,
      textSid: "",
      isLoad: false,
      utm_name: "",
      cantMens: "...",
      costMens: 0,
      costDolar: 0,
      selectedId:null,
      steps:STEPS,
      stepsfinish: false,
      success: null,
      successHours: "",
      successReturn: [],
      maxDate:null,
      isDiabledSegment: false,
      file: null,
      fileName: "",
      fileCount: 0,
      costFile: 0,
      costFileDolar: 0,
      setUrl: null,
      isDiabledHour: false,
      typeTemplate: -1,
      showinfo: true,
      statusWhatsapp: null,
      contentVariablesAssign: null
    };

    
    componentDidMount() {
      // source // SHOPIFY_SEGMENT o CSV
      this.inic();
      let domain = "";
      var shopJson =  Util.getAdminShop();
      let newC = {
        "name":"",
        "notifiedAt":"",
        "active": true,
        "source": null,
        "campaignData": {
          "source": null,
          "campaign": null,
          "medium": null,
          "segmentId": null,
          "segmentName": null,
          "audienceId": null,
          "audienceName": null,
          "audienceTotalCount": null,
          "contentSid": "",
          "contentVariables": {
            "1": "customer_first_name"
          }
        }
      };
      if (shopJson){
        if (shopJson.id !== "60585738305" && shopJson.id !== "8988636" && shopJson.id !== "60770517158"){
          newC.source = "SHOPIFY_SEGMENT";
        }
        domain = shopJson.domain;
        if(shopJson.enabledCampaigns){
        
          let isPay = (shopJson.plan && shopJson.plan.planType !== "50" && shopJson.plan.planType !== "0");
          this.setState({shop: shopJson, isPay: isPay});
          mixpanel.init(window.mixpanelKey); 
          mixpanel.track('Campaigns', {
            'shopDomain': shopJson.domain,
            'shopId': shopJson.id,
          });
        }

        if(shopJson.marketingCredential && shopJson.marketingCredential.twilioUsername && shopJson.marketingCredential.twilioPassword && shopJson.marketingCredential.senderId){
          Util.getStatusWhatsapp(shopJson.marketingCredential, this.setStatus);
        }
      }


      const { segment, dataExtra, type } = this.props;
      let typeTemplate = type === "BASIC" ? 1 : 2;
      if (segment){
        newC.campaignData.segmentId = segment.id;
        newC.campaignData.segmentName = segment.name;
        newC.source = "SHOPIFY_SEGMENT";
        console.log("Data extra", dataExtra);
        if (dataExtra){
          if (dataExtra.campaing){
            Object.keys(dataExtra.campaing).map((opt, index) => {
              console.log(opt, dataExtra.campaing[opt]);
              newC[opt] = dataExtra.campaing[opt];
            });
          }
          if (dataExtra.campaignData){
            Object.keys(dataExtra.campaignData).map((opt, index) => {
              console.log(opt, dataExtra.campaignData[opt]);
              newC.campaignData[opt] = dataExtra.campaignData[opt];
            });
          }
          if (dataExtra.noshowinformation){
            this.setState({showinfo: false});
          }

          if (dataExtra.extraDomains){
            let url = "https://"+domain+dataExtra.extraDomains;
            this.setState({setUrl: url});
          }else if (dataExtra.extraURL){
            this.setState({setUrl: dataExtra.extraURL});
          }
          

          if (dataExtra.nohour){
            let d = new Date();
            d.setTime(Date.parse(d.toLocaleString('en-US', { timeZone: shopJson.timeZone })));
            d.setHours(d.getHours() + 1);
            d= this.getDateT(d);
            newC["notifiedAt"] = d; 

            this.setState({isDiabledHour: true});
          }

          //setUrl
        }
        this.setState({isNew: true, newCampaing: newC, isDiabledSegment: true, typeTemplate: typeTemplate});
      }else{
        this.setState({newCampaing: newC, typeTemplate: typeTemplate});
      }
      
    }

    setStatus = (data) => {
      this.setState({statusWhatsapp: data});
    }

    checkCreate = () => {
      let isCheck =  false;
      const {reviews} = this.state;
      /*{reviews.map((item, key) => {
        if(item.state !== "PENDING"){
          isCheck =  true;
        }
      })}*/
      if (reviews && reviews.length > 0){
        isCheck =  true;
      }
      if(!isCheck){
        var shopJson =  Util.getAdminShop();
        if (shopJson.shopType === "TIENDANUBE"){
          isCheck =  true;
        }
      }
      if(isCheck){
        this.goToNew();
      }else{
        Util.getWriteCustomers(this.handleOkDiscount, this.handleOkDiscountError);
      }
      const { setOpenNew } = this.props;
      if (setOpenNew){
        setOpenNew();
      }
    }

    
    handleOkDiscount = (data) => {
        if (data && data.enabled){
          this.goToNew();
        }else{
          alert(Util.getMessage("update_campaign"));
          this.setState({enabledPriceRules: false});
        }
    }
    handleOkDiscountError = (data) => {
        console.log(data);
        alert(Util.getMessage("update_campaign"));
        this.setState({enabledPriceRules: false});       
    }

    inic = () => {
      let startDate = new Date();
      let endDate = new Date();
      startDate.setDate(endDate.getDate() - 360);
      const { filter, type } = this.props;
      filter.campaignType = type;
      filter.from = 0;
      filter.state = "";
      filter.size = 10000;
      filter.sortBy="notifiedAt";
      filter.sortOrder="desc";
      filter.fromDate=startDate.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}) + "T00:00:01";
      filter.toDate=endDate.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})+ "T23:59:59";
      this.setFilter(filter);
    }

    updateFilter = (fil) => {
      const { filter, type } = this.props;
      filter.campaignType = type;
      filter.from = 0;
      filter.state = "";
      filter.size = 10000;
      filter.sortBy="notifiedAt";
      filter.sortOrder="desc";
      filter.fromDate=fil.fromDate;
      filter.toDate=fil.toDate;
      this.setFilter(filter);
    }

    setFilter = (filter, isSearch=true) =>{
      this.setState({filterState: filter, isSearch: isSearch});
      Util.getCampaigns(filter, this.handleOk, this.handleError);
    }

    handleOk = (data) => {
      this.setState({reviews: data, reviewsList: data, isSearch: false});
    }

    handleError = (data) => {
      //this.setState({reviews: data});
    }

    handleChangePage = (page) => {

      const { filterState } = this.state;
      filterState["size"] = page;
      filterState.from = 0;
      this.setFilter(filterState);
      return false;  
    }  

    goTo = (from) => {
        const { filterState } = this.state;
        filterState.from = from;
        this.setFilter(filterState);
    }

    update = () => {
      const { filterState } = this.state;
      console.log("setfiltro")
      this.setFilter(filterState, false);
    }

    onChangeStar = (value) => {
      const { filterState } = this.state;
      filterState["stars"] = (value !== "" && value !== 0 ? value : null);
      this.setState({stars: value});
      this.setFilter(filterState);
      return false;  
    }


    searchFinish = () =>{
      const { filterState, timeOut } = this.state;
      clearTimeout(timeOut);
      if(filterState < 10){
        filterState.size = 15;
      }
      this.setFilter(filterState);
    }

    searchProducts = (text) =>{
      let { timeOut, filterState } = this.state;
      console.log(text);
      filterState["text"] = text;
      clearTimeout(timeOut);
      timeOut = setTimeout(function(){
        this.searchFinish();
      }.bind(this), 800);
      this.setState({timeOut: timeOut, filterState: filterState});
      
      return false;  
    }

    show = (product) => {
      const { show } = this.props;

      if (show){
        show(false);  
      }

      this.setState({reviews: [product], isShowProduct: true});
    }

    goToList = () => {
      const { reviewsList } = this.state;
      console.log("llamo ", reviewsList);
      this.setState({reviews: reviewsList, isShowProduct: false});
      const { show } = this.props;

      if (show){
        show(true);  
      }
    }

    setFilterOrder = (sortBy) => {
      const { filterState } = this.state;

      
      if(filterState.sortBy === sortBy){
        if (filterState.sortOrder === "desc" ){
          filterState.sortOrder="asc";
        }else{
          filterState.sortOrder="desc";
        }
      }else{
        filterState.sortBy=sortBy;
      }
      this.setFilter(filterState);
    }
  goToNew = () => {
      //this.setState({isNew: false, selectedId:null, details: false, isLoad: false, isShowProduct: false});
      const { isNew } = this.state;
      let newC = {
        "name":"",
        "notifiedAt":"",
        "active": true,
        "source": null,
        "campaignData": {
          "source": null,
          "campaign": null,
          "medium": null,
          "segmentId": null,
          "segmentName": null,
          "audienceId": null,
          "audienceName": null,
          "audienceTotalCount": null,
          "contentSid": "",
          "contentVariables": {
            "1": "customer_first_name"
          }
        }
      };
      this.setState({isNew: !isNew, selectedId:null, newCampaing: newC, wa: null, success: null,});
  }

  onBlurCampaing = (value, name) => {
    const { newCampaing } = this.state;
    newCampaing[name] = value; 
    this.setState({newCampaing: newCampaing});
  }

  onBlurCampaingTime = (value, name) => {
    const { newCampaing } = this.state;
    newCampaing[name+"h"] = value; 
    console.log(value);
    let d = new Date();
    let h = value.split(":")[0];
    d.setHours(h);
    value= this.getDateT(d);
    console.log(value);
    newCampaing[name] = value; 
    this.setState({newCampaing: newCampaing});
  }

  onChangeCampaing = (value, name) => {
    const { newCampaing, listhours, shop } = this.state;
    let d = value;
    let h = parseInt(value);
    if(listhours){
      let hnew = listhours[h-1];
      d = new Date(hnew);
      //d.setTime(Date.parse(d.toLocaleString('en-US', { timeZone: shop.timeZone })));
    }else{
      d = new Date(newCampaing.parentnotifiedAt);
      d.setHours(d.getHours() + h);
    }
    d= this.getDateT(d);
    //2024-03-19T12:41:50
    //2024-03-30T20:41
    //d = d.toISOString().replace(".000Z","");
    newCampaing[name] = d; 
    let dT = Date.parse(d);
    this.setState({newCampaing: newCampaing, successHours: h, notifiedAt: dT});
  }
  getDateT = (d) => {
    return d.getFullYear()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+((d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+"T" + ((d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+(d.getMinutes().toString().length==2?d.getMinutes().toString():"0"+d.getMinutes().toString()) +":"+(d.getSeconds().toString().length==2?d.getSeconds().toString():"0"+d.getSeconds().toString())) );
  }
  onBlurcampaignData = (value, name) => {
    const { newCampaing } = this.state;
    newCampaing.campaignData[name] = value; 

    // source // SHOPIFY_SEGMENT o CSV
    if(name === "segmentId" && value){
      newCampaing.source = "SHOPIFY_SEGMENT"; 
      newCampaing.campaignData.audienceId = null; 
      newCampaing.campaignData.audienceName = null; 
      newCampaing.campaignData.audienceTotalCount = null; 
    }else if(name === "segmentId"){
      newCampaing.source = null; 
    }

    this.setState({newCampaing: newCampaing});
  }


  newCampaigns = () =>{
    this.setState({isLoad: true});
    let { newCampaing, statusWhatsapp, fileCount, cantMens, contentVariablesAssign } = this.state;
    if(!(newCampaing.parentId && newCampaing.parentId !== "")){
      var count = (newCampaing["notifiedAt"].match(/:/g) || []).length;
      if(count===1){
        newCampaing["notifiedAt"]=newCampaing["notifiedAt"]+":00";
      }
    }
    const { type } = this.props;
    newCampaing["campaignType"] = type;

    if(statusWhatsapp && statusWhatsapp.properties && statusWhatsapp.properties.messaging_limit && Util.LIMITWHATSAPP[statusWhatsapp.properties.messaging_limit] < (fileCount === 0 ? cantMens : fileCount)){
      newCampaing.campaignData.customersLimit = Util.LIMITWHATSAPP[statusWhatsapp.properties.messaging_limit]; 
    }
    newCampaing.campaignData.contentVariables = contentVariablesAssign;

    if(newCampaing.campaignData.contentSid !== ""){
      if(newCampaing && newCampaing.id){
        Util.updateCampaign(newCampaing, this.newDetails, this.newError);
      }else{
        Util.newCampaigns(newCampaing, this.newDetails, this.newError);
      }
    }
  }
  newError = (data) => {
    this.setState({notify: Util.getMessage("error_campaign")});
  }
  remember = () =>{
    const { reviews } = this.state;
    if(!(reviews[0].parentId && reviews[0].parentId !== "")){
      this.setState({success: reviews[0], isNew:true});
      this.newOkVar(reviews[0]);
    }  
  }

  cancelCampaing = () =>{
    const { details } =  this.state;
    this.setState({details: !details, isLoad: false}); 
    //this.editCampaigns();
  }
  goBackCampaing = () =>{
    /*this.props.dispatch({
      type: 'CHANGE_MENU',
      new_option: '/campaigns-check-normal'
    });*/

    //this.goToNew();
    this.setState({details: false, isNew: false, selectedId:null});
  }
  gotoAudience = () => {
    this.props.dispatch({
      type: 'CHANGE_MENU',
      new_option: '/campaigns-check-normal-hearings'
    });
  }

  fileOk = (data) => {

  }
  
  fileError = (data) => {

  }
  
  newDetails = (data) =>{
    const { setOpenNew } = this.props;
    let { newCampaing, file, fileName } = this.state;
    

    if (setOpenNew){
      setOpenNew();
      this.setState({details: false, isNew: false});
    }else{
      
      if(!(newCampaing.parentId && newCampaing.parentId !== "")){
        if(file && data && data.id && data.source === "CSV"){
          Util.uploadFile(file, fileName, data.id, this.fileOk, this.fileError);
        }
        this.setState({success: data, isLoad: false});
      }else{
        this.goBackCampaing();
      }
    }
      
  }

  newOk = () => {
    
    const { success } = this.state;
    this.newOkVar(success);
  }

  newOkVar = (success) => {
    const { shop } = this.state;
    const MAX_HOURS = 23;
    let hours = 0;
    //let now= Date.now();
    let now= new Date();
    now.setTime(Date.parse(now.toLocaleString('en-US', { timeZone: shop.timeZone })));
    let date = new Date(success.notifiedAt);
    date.setHours(date.getHours() + 22);
    let max =  Date.parse(success.notifiedAt);
    max = max + (MAX_HOURS*60*60*1000);
    //max.setHours(max.getHours() + 22);
    if(now < max){
      hours = Math.abs(max - now) / 36e5;
    }
    if(hours > MAX_HOURS){
      hours = MAX_HOURS;
    }else{
      hours = Math.floor(hours);
    }
    let hoursArray = [""];
    let da = new Date(success.notifiedAt);
    
    //da.setHours(da.getHours() + 1);
    //console.log("hours", hours);
    if(hours !== MAX_HOURS){
      now= new Date();
      da.setTime(Date.parse(now.toLocaleString('en-US', { timeZone: shop.timeZone })));
      let maxMin =  new Date(success.notifiedAt);
      da.setMinutes(maxMin.getMinutes());
      //console.log("da", da);
      //da.setHours(now.getHours() + 1);
    }
    let listhours = [];
    
    for(let i = 1; i <= hours; i++){
      da.setHours(da.getHours() + 1);
      //console.log("da"+i, da);
      let ho = i < 10 ? "0"+i : i;
      hoursArray.push({id: i, name: `${ho} - ${("0" + da.getHours()).slice(-2)}:${da.getMinutes()}`});
      listhours.push(this.getDateT(da)); 
    }
    date= this.getDateT(date);
    //console.log(listhours);
    this.setState({
      details: false, 
      isLoad: false, 
      success: null,
      successHours: "",
      successReturn: hoursArray,
      listhours: listhours,
      notifiedAtMax:max,
      maxDate: date,
      wa: null,
      newCampaing: {
      "name":success.name+" [Reminder]",
      "notifiedAt":success.notifiedAt,
      "parentnotifiedAt":success.notifiedAt,
      "active": true,
      "parentId": success.id,
      "parentName": success.name,
      "source": success.source,
      "campaignData": {
        "source": null,
        "campaign": success.campaignData.campaign,
        "medium": success.campaignData.medium,
        "segmentId": success.campaignData.segmentId,
        "segmentName": success.campaignData.segmentName,
        "audienceId": success.campaignData.audienceId,
        "audienceName": success.campaignData.audienceName,
        "audienceTotalCount": success.campaignData.audienceTotalCount,
        "contentSid": "",
        "contentVariables": {
          "1": "customer_first_name"
        }
      }
    }});
    this.setInputSegment(success.campaignData.segmentName);
    /*setTimeout(function(){  
      this.onChangeCampaing(5, "notifiedAt");
    }.bind(this), 800);*/
  }

  editCampaigns = () =>{
    const { reviews } = this.state;
    let idMysql = null;
    if(reviews[0].source && reviews[0].source === "SHOPIFY_CHECKOUT"){
      this.setState({isDiabledHour: true, setUrl: "https://revie.cc/{{2}}",showinfo: false, isDiabledSegment: true,});
    }
    try{
      idMysql = reviews[0].campaignData.campaign.split("_");
      idMysql = idMysql[(idMysql.length - 1)];
    }catch(e){
      console.log(e);
    }
    this.setState({
      isNew: true,
      newCampaing: reviews[0],
      selectedId: idMysql
    })

    //this.setInputSegment(reviews[0].campaignData.segmentName);
  }

  setInputSegment = (segmentName) =>{
    /*setTimeout(function(){
      let inputSegment = document.getElementById("input-segment");

      if(inputSegment){
        inputSegment.value = segmentName;
      }
    }.bind(this), 800);*/
  }

  openModal = () => {
    let modal = document.getElementById("myModal2");
    const { newCampaing } = this.state;
    if(modal){
        modal.remove();
    }
    document.body.insertAdjacentHTML('afterbegin', `<div id="myModal2" class="modal">
        <span class="close">&times;</span>
        <div id="caption">
        <div class="text">${Util.getMessage("contentSid_table")}<div><input id="segment" value="${newCampaing && newCampaing.campaignData &&newCampaing.campaignData.contentSid}" class="input-form"></div></div>
        <div class="text">${Util.getMessage("phone_table")}<div><input id="phone" placeholder="52XXXXXXXXXXX" type="phone" class="input-form" value=""></div></div>
        <div class="text">${Util.getMessage("confirm_campaign_1")}<div><input id="name" placeholder="Juan Perez" type="text" class="input-form" value=""></div></div>
        <button type="button" id="clickTest" class="button primary">${Util.getMessage("test_campaigns")}</button>
        </div>
    </div>`);
    
    modal = document.getElementById("myModal2");
    // Get the image and insert it inside the modal - use its "alt" text as a caption
    //var modalImg = document.getElementById("img01");
    var clickTest = document.getElementById("clickTest");
    var header = document.getElementsByClassName("header")[0];
    var menu = document.getElementsByClassName("menu")[0];
    
    modal.style.display = "block";
    header.style.zIndex=0;
    menu.style.zIndex=0;

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function() { 
        modal.style.display = "none";
        modal.remove();
        header.style.zIndex=99;
        menu.style.zIndex=99;
    }
    clickTest.onclick = function() { 
      var segment = document.getElementById("segment");
      var phone = document.getElementById("phone");
      var name = document.getElementById("name");
      if(phone.value !== "" && segment.value !== ""){
        let newCampaingTest = {
          "testPhone": "+"+phone.value,
          "testFirstName": name.value,
          "contentSid":segment.value,
          "contentVariables":{
             "1":"customer_first_name"
          }        
        };
        Util.newCampaignsTest(newCampaingTest);
        modal.style.display = "none";
        modal.remove();
        header.style.zIndex=99;
        menu.style.zIndex=99;
        alert(Util.getMessage("ok_test_campaign"));
      }else{
        alert(Util.getMessage("error_campaign_alert"));
      }
    }
}

  setCS = (sid) => {
    this.onBlurcampaignData(sid, "contentSid");
  }
  setWA = (wa) => {
    this.setState({wa: wa});
  }
  onSegments = (value, name, text) => {

    console.log("value", value);
    if(value === ""){
      this.onBlurcampaignData(null, name);
    this.setState({textSid: ""})
    }else{
      this.onBlurcampaignData(value, name);
      this.setState({textSid: text})
    }
    
  }

  reemplaceName = (name) => {
    let newName = name.replaceAll(" ","_").normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    return newName;
  }
  setStep = (step) =>{
    let { steps } = this.state;
    if(step - 1 >= 0){
      steps[step-1].loader=false;
      steps[step-1].check=true;
    }
    if(step < steps.length){
      steps[step].loader=true;
    }
    
    this.setState({ steps: steps });
  }

  checkTemplate = (contentSid, timer) =>{
    const { wa, shop } = this.state;
    if(wa){
      let newWA = wa;
      newWA.sid=contentSid;
      if(shop && shop.marketingCredential){
        newWA.marketingCredential = shop.marketingCredential;
      }
      const url = window.url_server_2+"setcontent/check.php?shop="+Util.getShopDomain();
      wa["accessTokenStore"] = Constants.getAccessTokenStore();
      axios.post(url, newWA, {}).then(response => response.data,error => {
          return error;
        }).then((data) => {
          if (data && data.code === "200"){
            console.log(data);
            let template = JSON.parse(data.data);
            console.log(template);
            this.setStep(2);
            if(template && template.whatsapp && (template.whatsapp.status === "received" || template.whatsapp.status === "pending")){
              setTimeout(function(){
                this.checkTemplate(contentSid, timer + 12000);
              }.bind(this), timer);
            }else if(template && template.whatsapp && template.whatsapp.status === "rejected"){
              let { steps } = this.state;
                steps[2].loader=false;
                steps[2].error=true;
                steps[2].message=template.whatsapp.rejection_reason;
              
              this.setState({ steps: steps });
            }else if(template && template.whatsapp && template.whatsapp.status === "approved"){
              this.setStep(3);
              this.setCS(contentSid);
              this.setState({stepsfinish: true});
            }else if(template.message){
              let { steps } = this.state;
                steps[2].loader=false;
                steps[2].error=true;
                steps[2].message=template.message;
              
              this.setState({ steps: steps });
            }
            
            
          }else{
            alert("Ocurrio un error al crear la plantilla, comuniquese con Revie!");
          }
      });
    }
  }

  getUTM = (websiteurl, name, id, random) => {
    
    let utm_name = "";
    //utm  ?utm_source=revie&utm_medium=whatsapp&utm_campaign=XXXXX_DDMMAA
    let newURL = websiteurl;
      let array = newURL.split("?");
      let notUTM = true;

      let conector = "?";
      if(array.length > 1){
        conector = "&";
        const searchParams = new URLSearchParams(array[1]);
        if(searchParams.has("utm_campaign")){
          //utm_name = searchParams.get("utm_campaign");
          notUTM = false;
        }
      }
      //if(notUTM){
        let dateName = new Date().toLocaleDateString(window.paramRevie.language, {year: 'numeric', month: '2-digit', day: '2-digit'}).replaceAll("/","").replaceAll("-","");
        utm_name = this.reemplaceName(name)+"_"+dateName+"_"+random+"_"+id ;
      //}
    return {notUTM : notUTM, utm_name : utm_name, conector: conector};
  }

  createTemplate = () =>{
    const { wa, shop } = this.state;
    this.setState({isLoad: true});
    let newWA = wa;
    let newURL = newWA.websiteurl;
    let random = Math.floor(Math.random() * 99999);
    let acortador = true;
        
    let utm_name_obj = this.getUTM(newWA.websiteurl, newWA.name, wa.id, random);
    //utm  ?utm_source=revie&utm_medium=whatsapp&utm_campaign=XXXXX_DDMMAA
    /*let newURL = newWA.websiteurl;
      let array = newURL.split("?");
      let notUTM = true;

      let conector = "?";
      if(array.length > 1){
        conector = "&";
        const searchParams = new URLSearchParams(array[1]);
        if(searchParams.has("utm_campaign")){
          utm_name = searchParams.get("utm_campaign");
          notUTM = false;
        }
      }*/
    if(!utm_name_obj.notUTM){
      newURL = newURL.replace("utm_campaign", "utm_campaign_back");
    }
        /*let dateName = new Date().toLocaleDateString(window.paramRevie.language, {year: 'numeric', month: '2-digit', day: '2-digit'}).replaceAll("/","").replaceAll("-","");
        let random = Math.floor(Math.random() * 99999);
        utm_name = this.reemplaceName(newWA.name)+"_"+dateName+"_"+random+"_"+wa.id ;*/
        newURL = newURL+utm_name_obj.conector+`utm_source=revie&utm_medium=whatsapp&utm_campaign=`+utm_name_obj.utm_name;
        if(newWA.websiteurl !== "https://revie.cc/{{2}}"){//Acortador
          newWA.websiteurl = newURL;
          acortador = false;
        }
    //}

    if(newWA.websiteurl !== "https://revie.cc/{{2}}"){//Acortador
      newWA.websiteurl = newURL;
      acortador = false;
    }

    if(newWA && newWA.cards){
      for (let index = 0; index < newWA.cards.length; index++) {
        const element = newWA.cards[index];
        let newURL = element.actions[0].url;
        let utm_name_obj = this.getUTM(element.actions[0].url, newWA.name, newWA.id, random);
        if(utm_name_obj.notUTM){
          newURL = newURL+utm_name_obj.conector+`utm_source=revie&utm_medium=whatsapp&utm_campaign=`+utm_name_obj.utm_name;
          if(element.actions[0].url !== "https://revie.cc/{{2}}"){//Acortador
            element.actions[0].url = newURL;
            acortador = false;
          }
        } 
      }
    }

    const { newCampaing, textSid } = this.state;
    newCampaing.campaignData["source"] = "revie"; 
    newCampaing.campaignData["campaign"] = utm_name_obj.utm_name; 
    newCampaing.campaignData["medium"] = "whatsapp"; 
    if(textSid && newCampaing.campaignData["segmentName"] === null){
      newCampaing.campaignData["segmentName"] = textSid;   
    }
    let contentVariablesAssign={};
    console.log("newWA", newWA);
    if(newWA.body && newWA.body.includes("{{1}}")){
      contentVariablesAssign={
        "1":"customer_first_name"
      };
      if(acortador){
        contentVariablesAssign={"1":"customer_first_name","2":"abandoned_checkout_url"};
      }
    }else{
      if(acortador){
        contentVariablesAssign={"1":"abandoned_checkout_url"};
      }
    }

    
    
    this.setState({newCampaing: newCampaing, utm_name: utm_name_obj.utm_name, isLoad: false, contentVariablesAssign: contentVariablesAssign});

    if(shop && shop.marketingCredential){
      newWA.marketingCredential = shop.marketingCredential;
    }
    const url = window.url_server_2+"setcontent/create.php?shop="+Util.getShopDomain();
    wa["accessTokenStore"] = Constants.getAccessTokenStore();
    newWA["contentVariablesAssign"]=contentVariablesAssign;
    axios.post(url, newWA, {}).then(response => response.data,error => {
        return error;
      }).then((data) => {
        if (data && data.code === "200"){
          let contentSid = data.data.sid;
          //this.setCS(contentSid);
          this.setStep(1);
          setTimeout(function(){
            this.setStep(2);
            this.checkTemplate(contentSid, 10000);
          }.bind(this), 1500);
          
        }else{
          alert("Ocurrio un error al crear la plantilla, comuniquese con Revie!");
        }
    });
  }

  showPreview = () =>{
    let steps = STEPS;
    steps[0].loader=true;
    steps[1].loader=false;
    steps[1].check=false;
    steps[2].error=false;
    steps[2].loader=false;
    steps[2].message=null;
    steps[2].check=false;
    this.setState({details: true, steps: steps, stepsfinish: false });
    this.createTemplate();
    this.calculateSegment();
  }

  calculateSegment = () =>{
    const { newCampaing, fileCount } = this.state;
    if(newCampaing.campaignData.segmentId !== "" && fileCount === 0){
      Util.getSegmentsCount(this.segmentOK,this.segmentError,newCampaing.campaignData.segmentId);
    }else if(fileCount > 0){
      Util.getCurrency(this.isOKRates);
    }
  }
  segmentOK = (data) =>{
    if(data && data.totalCount){
      const { shop } = this.state;
      let costMens = data.totalCount * (COST[shop["countryCode"]] ? COST[shop["countryCode"]] : 0.1 );
      console.log("costMens", costMens);
      /*let costMens = data.totalCount * (COST[shop["countryCode"]] ? COST[shop["countryCode"]] : 0.1 )
      this.setState({cantMens: data.totalCount, costMens: costMens});*/
      this.setState({cantMens: data.totalCount});
      Util.getCurrency(this.isOKRates);
    }
  }
  isOKRates = (data) => {
    const { shop, cantMens, fileCount } = this.state;
    let shopify =  shop;
    if (shopify && shopify.countryCode && data[shopify.countryCode]){
        let dat = data[shopify.countryCode];

        let costMenssage = dat.mkt * dat.rate;
        costMenssage = cantMens * costMenssage;
        
        let costMenssageDolar = dat.mkt;
        costMenssageDolar = cantMens * costMenssageDolar;

        let costFile = 0;
        let costFileDolar = 0;
        
        if(fileCount > 0){

          //console.log("fileCount", fileCount, dat.mkt, dat.rate, (dat.mkt * dat.rate));
          costFile = fileCount * (dat.mkt * dat.rate);
          costFileDolar = fileCount * (dat.mkt);
        }

        this.setState({costMens: costMenssage, costDolar: costMenssageDolar, costFile: costFile, costFileDolar: costFileDolar});
        
    }
}
  
  segmentError = (data) =>{

  }

  setCSV = (data) =>{
// source // SHOPIFY_SEGMENT o CSV
    if(data){
      const { newCampaing } = this.state;
      newCampaing.source = "CSV"; 
      newCampaing.campaignData.audienceId = data.id; 
      newCampaing.campaignData.audienceName = data.name; 
      newCampaing.campaignData.audienceTotalCount = data.total; 
      newCampaing.campaignData.segmentId = null; 
      newCampaing.campaignData.segmentName = null;
      this.setState({newCampaing: newCampaing, fileCount: data.total});
    }
  }

  clear = () =>{
    const { newCampaing } = this.state;
    newCampaing.source = null; 
    newCampaing.campaignData.audienceId = null; 
    newCampaing.campaignData.audienceName = null; 
    newCampaing.campaignData.audienceTotalCount = null; 
    newCampaing.campaignData.segmentId = null; 
    newCampaing.campaignData.segmentName = null;
    console.log("newCampaing", newCampaing);
    this.setState({newCampaing: newCampaing, fileCount: 0});
  }
  
    render() {

      const { reviews, isSearch, statusWhatsapp, showinfo, fileCount,typeTemplate, isDiabledHour, setUrl, costFile, costFileDolar, cantMens, isDiabledSegment, notifiedAt, notifiedAtMax , successHours, costMens, costDolar, success, details, wa, stepsfinish, steps, isLoad, textSid, filterState, isPay, isShowProduct, shop, isNew, successReturn, newCampaing, notify, enabledPriceRules, selectedId } = this.state;
      const { type } = this.props;
      if(details){
        return (
          <div className='content-reviews'>
            <div className='title title-spacial content-flex space-between space-top'>
              {Util.getMessage("confirm_campaign")} 
                <Button value={Util.getMessage("Cancel")} type="primary" onClick={() => this.cancelCampaing()}/>
            </div>
            <div className='details-wa preview'>
              <div className='crate-wa'>
                <div>
                  <div className='cont-wp'>
                    <WhatsApp message={wa} />
                  </div>
                </div>
              </div>
              <div>
                <div className='det'>
                  <label>{Util.getMessage("confirm_campaign_1")}<b>{newCampaing.name}</b></label>
                  {!isDiabledHour && type === "BASIC" ? (
                    <>
                      <label className='dateInput'>{Util.getMessage("confirm_campaign_2")}<b><InputHtml className="" type="datetime-local" edit={true} name="notifiedAt" value={newCampaing.notifiedAt}/></b></label>
                    </> ) : !isDiabledHour ? (
                      <>
                      <label className='dateInput'>{Util.getMessage("confirm_campaign_2_2")}: <b><InputHtml className="" type="time" edit={true} name="notifiedAt" value={newCampaing.notifiedAth}/></b></label>
                    </>
                    ) : null}
                  {/*{newCampaing.campaignData.audienceTotalCount === null ? (<label>{Util.getMessage(type === "BASIC" ? "confirm_campaign_3" : "confirm_campaign_3_2")}<b>{textSid ? textSid : newCampaing.campaignData["segmentName"] ? newCampaing.campaignData["segmentName"] : "..."}</b></label>) : <label>{Util.getMessage("confirm_campaign_CVS")} <b>{newCampaing.campaignData.audienceTotalCount}</b></label>}*/}
                  {showinfo ? (
                    <>
                    <label>{Util.getMessage("confirm_campaign_4")}<b>{newCampaing.campaignData.contentSid}</b></label>
                    <label>{Util.getMessage("confirm_campaign_5")}<b>{fileCount === 0 ? cantMens : fileCount}</b></label>
                    {type === "BASIC" ? (
                      <>
                        <label>{Util.getMessage("confirm_campaign_6")}<b><CurrencyFormat value={(newCampaing.parentId && newCampaing.parentId !== "") ? (costFile === 0 ? costMens: costFile)*0.3 : (costFile === 0 ? costMens: costFile)} decimalSeparator="," thousandSeparator={"."} decimalScale={2} displayType={'text'} prefix={'$'} /></b> (US$ <CurrencyFormat value={(newCampaing.parentId && newCampaing.parentId !== "") ? (costFileDolar === 0 ? costDolar: costFileDolar)*0.3 : (costFileDolar === 0 ? costDolar: costFileDolar)} decimalSeparator="," thousandSeparator={"."} decimalScale={2} displayType={'text'} prefix={'$'} />) (*)</label>
                      </> ) : null}
                    <label style={{fontSize: "13px"}}><small>{Util.getMessage("confirm_campaign_7")}</small></label>
                    </>
                  ) : null}
                </div>

                {(success && !(newCampaing.parentId && newCampaing.parentId !== "") && type === "BASIC")? (
                  <div className='success-content'>
                    <div className='content-flex flex-start'>
                      <div dangerouslySetInnerHTML={{__html: SUCCESS}} /> {Util.getMessage("success_campaign")}
                    </div>
                    <RememberProm costMens={costMens} cancelCampaing={this.goBackCampaing} newOk={this.newOk}/>
                  </div>
                ) : (
                  <>
                  <div className='step-items'>
                    {steps.map(step => (
                      <Step isLoader={step.loader} key={step.id} isCheck={step.check} isError={step.error}>
                        <div>
                          {step.content}
                          {step.message ? (<small className='error-mens'>{step.message}</small>):null}
                        </div>
                      </Step>
                    ))}
                  </div>
                  <small style={{paddingBottom: "1rem",display: "block"}}>(*) {Util.getMessage("confirm_campaign_8")}</small>
                  {(statusWhatsapp && statusWhatsapp.properties && statusWhatsapp.properties.messaging_limit && Util.LIMITWHATSAPP[statusWhatsapp.properties.messaging_limit] < (fileCount === 0 ? cantMens : fileCount)) ? (
                    <>
                      <small className='error' style={{fontSize: "20px", lineHeight: "28px"}} dangerouslySetInnerHTML={{__html: Util.getMessage("limit_whatsapp").replace("{limit}", Util.LIMITWHATSAPP[statusWhatsapp.properties.messaging_limit])}} />
                    </>
                  ) : null}
                  <div className='text button_campaign t3'>
                    <Button value={newCampaing.id ? Util.getMessage("Editar") : Util.getMessage("Crear")} disabled={isLoad || !stepsfinish ||newCampaing.name === "" || newCampaing.notifiedAt === "" || (newCampaing.campaignData.segmentId === "" && newCampaing.campaignData.audienceId === "") || newCampaing.campaignData.contentSid === ""} type="primary" onClick={() => this.newCampaigns()}/>
                    <Button value={Util.getMessage("test_campaigns")} disabled={!stepsfinish || newCampaing.name === "" || newCampaing.notifiedAt === "" || newCampaing.campaignData.segmentId === "" || newCampaing.campaignData.contentSid === ""} type="third" onClick={() => this.openModal()}/>
                    <Button value={Util.getMessage("Volver")} onClick={() => {this.cancelCampaing()}}/>
                  </div>
                  <div className='text-help' dangerouslySetInnerHTML={{__html: Util.getMessage("confirm_campaign_help")}}/>
                  </>
                )}
              </div>
            </div>
            {notify !== "" && (
            <Notify text={notify} />
          )}
        </div>
        );
      }else if(isNew && newCampaing){
        return (
          <div className="content-reviews">
            <div className='title title-spacial content-flex space-between space-top'>
                {Util.getMessage(isShowProduct ? "edit_campaigns" :"new_campaigns")}
                <Button value={Util.getMessage("Cancel")} type="primary" onClick={() => this.goToNew()}/>
            </div>
            <div className='content-config nb'>
              <div className='text cont-min'>
                {Util.getMessage("Name_table")} 
                <InputHtml className="" disabled={(newCampaing.parentId && newCampaing.parentId !== "")} edit={true} name="name" isOnlyTL={true} value={newCampaing.name} onBlur={this.onBlurCampaing}/>
              </div>
              {(newCampaing.parentId && newCampaing.parentId !== "") ? (
                <div className='text cont-min'>
                  {Util.getMessage("remember_4")} 
                  <SelectHtml className="" type="datetime-local" edit={true} name="notifiedAt" value={successHours} onChange={this.onChangeCampaing} isKey={false} values={successReturn}/>
                  {successHours != "" ? (
                  <>
                    {Util.getMessage("remember_5")} 
                    <InputHtml disabled={true} className="" type="datetime-local" edit={true} name="notifiedAt" value={newCampaing.notifiedAt}/>
                  </>
                  ) : null}
                </div>
              ) : !isDiabledHour ? (
                <div className='text cont-min'>
                  {type === "BASIC" ? (
                    <>
                      {Util.getMessage("date_crate_table")} 
                      <InputHtml className="" type="datetime-local" edit={true} name="notifiedAt" value={newCampaing.notifiedAt} onBlur={this.onBlurCampaing}/>
                    </>
                  ) : (
                    <>
                      {Util.getMessage("date_crate_table_1")} 
                      <InputHtml className="" type="time" edit={true} name="notifiedAt" value={newCampaing.notifiedAth} onBlur={this.onBlurCampaingTime}/>
                    </>
                  )}
                  
                </div>
              ) : null}
              <div className='text'>
                <div className='flex-10'>{Util.getMessage("audience_crate_table")} {Util.isTiendaNube(Util.getAdminShop()) && !((newCampaing.parentId && newCampaing.parentId !== "") || isDiabledSegment) ? <Button value={Util.getMessage("add_audience")} type="primary" onClick={() => this.gotoAudience()}/> : null}</div>
                <div className='sptop'>
                      <ListSegments 
                        name="segmentId" 
                        maxHeight="250px" 
                        value={newCampaing.campaignData.segmentId} 
                        valueCVS={newCampaing.campaignData.audienceId} 
                        nameCVS={newCampaing.campaignData.audienceName} 
                        onSegments={this.onSegments} 
                        setCSV={this.setCSV}
                        clear={this.clear}
                        category={newCampaing.source === null ? "all" : newCampaing.source === "CSV" ? "list" : "segment"}
                        disabled={(newCampaing.parentId && newCampaing.parentId !== "") || isDiabledSegment}
                      />
                    </div>
              </div>
              {typeTemplate !== -1 ? (
                <div className='text'>
                  {Util.getMessage("contensid_crate_table")} 
                  <ContentSid selectedId={selectedId} setCS={this.setCS} setWA={this.setWA} setUrl={setUrl} typeTemplate={typeTemplate}/>
                </div>
              ) : null}
              <div className='text button_campaign'>
                <Button value={Util.getMessage("btn_continue")} loader={isLoad} disabled={(newCampaing.parentId && newCampaing.parentId !== "" && (notifiedAt > notifiedAtMax || successHours === "")) || newCampaing.name === "" || newCampaing.notifiedAt === "" || (newCampaing.source === null) || wa === null} type="primary" onClick={() => this.showPreview()}/>
              </div>
              
            </div>
          </div>
          );
      }else{
        return (
            <div className="content-reviews">
                {!isShowProduct ? (
                  <>
                    <Filter isIntervalShow={false} className="anaFilter" setFilter={this.updateFilter} filter={filterState} fromText="fromDate"  toText="toDate"/>
                    <div className='title content-flex space-between'>
                      {Util.getMessage(type === "BASIC" ? "Campaigns" : "FLOW_CAMPAIGNS")}
                      {Util.enabledCampaigns() ? (
                        <Button value={Util.getMessage("create")} type="primary" onClick={() => this.checkCreate()}/>
                      ) : null}
                    </div>
                    {!enabledPriceRules && (
                    <div>
                        <b>{Util.getMessage("TEXT_ENABLED_CAMPAIGN")} <a href={window.url_server_2+"view/update/?shop="+Util.getMetafieldMyshopify_domain()}>LINK</a></b>
                    </div>
                    )}
                    <input type="search" value={filterState && filterState.text ? filterState.text : ""} onChange={(e) => this.searchProducts(e.target.value)} placeholder={Util.getMessage("search_products")} className="search-products" />
                  </>
                ) : (
                  <div className='title content-flex space-between'>
                    <span></span>
                    </div>
                )}
                  <div className='content-products'>
                  
                  {isSearch ? (
                    <div className='search'>
                      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>
                  ) : (
                    <>
                      <ListCampaigns type={type} remember={this.remember} hide={this.goToList} show={this.show} shop={shop} isShowProduct={isShowProduct} campaigns={reviews} isPay={isPay} isShowTitle={false} title={"campaings_list"} handleOk={this.update} filter={filterState} setFilterOrder={this.setFilterOrder} edit={this.editCampaigns}/> 
                      {!isShowProduct && reviews && reviews.length > 0 && reviews.length >= filterState.size && (
                        <Page from={filterState.from} size={filterState.size} elementSize={reviews.length} handleClick={this.goTo} handleChangePage={this.handleChangePage}/>
                      )}  
                      <div className='spacing'></div>
                    </>
                  )}
                </div>
            </div>
        );
        }
      }
    }
const mapStateToProps = (state) => ({
    shop: state.shop,
    filter: state.filter
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaigns);
